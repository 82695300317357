import React from "react";
import { Link } from "react-router-dom";
import Change from "../../../components/Change/Change.tsx";
import "./SearchDrawerItem.css";

const SearchDrawerItem = ({
    imgSrc,
    title,
    change,
    price,
    id
}) => {

    return (
        <Link to={"/meme/" + id}>
            <div className="search-drawer-item">
                <img src={imgSrc} alt="meme" className="search-drawer-item__img"/>
                <p className="search-drawer-item__title">{title}</p>
                <Change value={change}></Change>
                <p className="search-drawer-item__price">{price}</p>
            </div>
        </Link>
    );
};

export default SearchDrawerItem;
