import React, { useEffect, useState } from 'react'
import {
	addMemeToFavorite,
	getMemeList,
	getUserMemes,
	removeMemeFromFavorite
} from '../../core/api/MemeTradeApi';
import { useUser } from '../../lib/hooks/useUser.ts';
import { FlexboxGrid, List, Pagination, Stack } from 'rsuite';
import MemeDisplay from "../../components/MemeDisplay/MemeDisplay";
import './MemeTable.css'
import {useNotification} from "../../lib/hooks/useNotification.ts";
import {toUpper} from "lodash";


export const MemeTable = ({paginator= true}) => {

	const { isAuth, token } = useUser();
	const [processedData, setProcessedData] = useState([]);
	const [pageState, setPageState] = useState(1);
	const [loadingState, setLoadingState] = useState(false);
	const [maximumPage, setMaximumPage] = useState(1);
	const [sortBy, setSortBy] = useState("population");
	const [order, setOrder] = useState("asc");

	const {notify} = useNotification();

	const toggleItemInFavorite = async (id, addToFav = false) => {
		const apiFunction = addToFav ? addMemeToFavorite : removeMemeFromFavorite;
		try {
			await apiFunction(token, id);
			setProcessedData((state) =>
				state.map((item) => {
					if (item.id !== id) return item;
					return { ...item, favorite: addToFav };
				})
			);
		} catch (err) {
			notify("error", err)
		}
	};

	const fetchAndProcessMemes = async (page, title) => {
		try {
			setLoadingState(true);
			const data = await getMemeList(page, 10, "", sortBy.toUpperCase(), order.toUpperCase());
			let favorites = {user_memes: []};
			if (isAuth) {
				favorites = await getUserMemes(token, true);
			}
			const processedData = data.memes.map((item) => ({
				...item,
				favorite: !!favorites.user_memes.find(favorite => favorite.meme.id === item.id),
			}));
			setMaximumPage(data["amount_of_pages"])
			setProcessedData(processedData);
			setLoadingState(false);

		} catch (err) {
			notify("error", err)
		}
	};

	const onSortColumn = (sortBy, order) => {
		setSortBy(sortBy)
		setOrder(order)
	};

	useEffect(() => {
		fetchAndProcessMemes(pageState);
	}, [pageState, isAuth, sortBy, order]);

	return (
		<div className={"meme-table"}>
			<MemeDisplay
				memes={processedData}
				toggleItemFavorite={toggleItemInFavorite}
				onSortColumn={onSortColumn}
				sortColumn={sortBy}
				sortType={order}
				height={740}
			/>
			{paginator && <Pagination
								style={{alignSelf: 'center'}}
								prev
								last
								next
								first
								ellipsis
								boundaryLinks
								maxButtons={10}
								limit={10}
								total={maximumPage * 10}
								activePage={pageState}
								onChangePage={setPageState}
			/>}
		</div>
	);
}
