import { FlexboxGrid } from "rsuite";
import React, {ReactNode} from "react";
import "./KeyValuePairDisplay.css"

interface KeyValuePairDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
    label: string,
    value?: string | number | ReactNode,
};

const KeyValuePairDisplay: React.FC<KeyValuePairDisplayProps> = ({
    label,
    value = "",
}) => {
    return (
        <FlexboxGrid style={{ lineHeight: "2rem" }} justify='space-between'>
            <span className="key-value-pair__label">{label}</span>
            <span className="key-value-pair__value">{value}</span>
        </FlexboxGrid >
    )
};

export default KeyValuePairDisplay;
