import React, {HTMLProps} from 'react';
import "./Change.css"

interface ChangeProps {
    value: number;
    props?: HTMLProps<HTMLSpanElement>
}

const Change: React.FC<ChangeProps> = ({value, ...props}) => {

    if (value >= 0) {
        return (
            <span {...props} className="change--up">
                +{value}% ▲
            </span>
        );
    }
    return (
        <span {...props} className="change--down">
            {value}% ▼
        </span>
    )
};

export default Change;
