import React, { useEffect, useState } from 'react'

import './TrendingList.css'
import { Carousel, Stack } from 'rsuite'
import { getTrendingMemes } from '../../core/api/MemeTradeApi';
import TrendingCard from './TrendingCard/TrendingCard';

const CAROUSEL_STYLES = {
    height: "150px",
};

export const TrendingList = () => {

    const [index, setIndex] = useState(0);
    const [memesData, setMemesData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        try {
            setLoading(true);
            const response = await getTrendingMemes(12);
            setMemesData(response.memes);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }

    }, []);

    return <Carousel
        autoplay
        shape={"bar"}
        style={CAROUSEL_STYLES}
    >
        <Stack justifyContent='space-around' spacing={10}>
            {memesData.map((item, index) => {
                if (index < 4) return ( <Stack.Item grow={1} key={"trending_memes_" + index}>{<TrendingCard imageUrl={item.picture} memeName={item.title} value={item.current_price} percentageChange={item.fluctuation} id={item.id} />}</Stack.Item>)
            })}
        </Stack>
        <Stack justifyContent='space-around' spacing={10}>
            {memesData.map((item, index) => {
                if (index > 3 && index < 8) return ( <Stack.Item grow={1} key={"trending_memes_" + index}>{<TrendingCard imageUrl={item.picture} memeName={item.title} value={item.current_price} percentageChange={item.fluctuation} id={item.id} />}</Stack.Item>)
            })}
        </Stack>
        <Stack justifyContent='space-around' spacing={10}>
            {memesData.map((item, index) => {
                if (index > 7 && index < 13) return ( <Stack.Item grow={1} key={"trending_memes_" + index}>{<TrendingCard imageUrl={item.picture} memeName={item.title} value={item.current_price} percentageChange={item.fluctuation} id={item.id} />}</Stack.Item>)
            })}
        </Stack>
    </Carousel>
}
