import React from 'react';
import './TrendingCard.css';
import Title from "../../Title/Title.tsx";
import Change from "../../Change/Change.tsx";
import {Link} from "react-router-dom";

const TrendingCard = ({ imageUrl, memeName, percentageChange, value, id }) => {
	return (
		<div className="trending-meme-card">
			<div className="trending-meme-card__header">
				<img src={imageUrl} alt="Meme" className="trending-meme-card__image"/>
				<Link to={"/meme/" + id} className="trending-meme-card__title">{memeName}</Link>
			</div>
			<div className="trending-meme-card__stats">
				<Change value={percentageChange}/>
				<span className="trending-meme-card__value">{value}</span>
			</div>
		</div>
	);
};

export default TrendingCard;
