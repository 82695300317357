import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { getMemeList } from "../../core/api/MemeTradeApi";
import { useRecoilState } from "recoil";
import { SEARCH_DRAWER_STATE } from "../../core/store/app.ts";
import { Drawer, Input, InputGroup, List, Loader, Message, Panel, Placeholder, Stack } from "rsuite";
import Search from "@rsuite/icons/Search";
import SearchDrawerItem from "./SearchIDrawerItem/SearchDrawerItem.jsx";

const INPUT_STYLES = {
    width: '100%',
    marginBottom: 10,
    marginTop: 10
};

export const SearchDrawer = () => {

    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useRecoilState(SEARCH_DRAWER_STATE);

    const fetchResults = debounce((query) => {
        if (!query) {
            setResults([]);
            setIsLoading(false);
            return;
        }
        getMemeList(1, 10, query)
            .then(res => {
                setResults(res.memes);
                console.log(query)
                console.log(results)
                setIsLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
            })
    }, 400);

    useEffect(() => {
        return () => fetchResults.cancel();
    }, []);

    const handleChange = (value) => {
        setError(null);
        setInput(value);
        if (value) {
            setIsLoading(true);
            fetchResults(value);
        }
    };

    const handleOnClose = () => setIsOpen(false);

    return (
        <Drawer open={isOpen} onClose={handleOnClose}>
            <Drawer.Header>
                <Drawer.Title>Search memes</Drawer.Title>
                <Drawer.Actions>
                    <InputGroup>
                        <Input value={input} onChange={handleChange} />
                        <InputGroup.Addon>
                            <Search />
                        </InputGroup.Addon>
                    </InputGroup>
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
                {isLoading && <Loader size="lg" center content="loading" />}
                {error && <Message type="error">Encoutered error while searching your memes :(</Message>}
                {results?.length > 0 && (
                    <List hover direction="column">
                        {results.map(item => (
                            <List.Item key={item.id}><SearchDrawerItem imgSrc={item.picture} title={item.title} price={item.current_price} change={item.fluctuation} id={item.id} /></List.Item>
                        ))}
                    </List>
                )}
            </Drawer.Body>
            <Drawer.Body>
                <Placeholder.Paragraph>

                </Placeholder.Paragraph>
            </Drawer.Body>
        </Drawer>
    );
}
