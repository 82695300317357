import React from 'react'
import './PageLoader.css'

export const PageLoader = ({ message }) => {
	return (
		<div className='pageloaderContainer'>
			<div className='lds-default'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div className='pageloaderMessage'>{message}</div>
		</div>
	)
}
