import React from "react";
import Table from "rsuite/Table";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {Link} from "react-router-dom";
import Doge from "../../assets/svg/Doge.tsx";
import Change from "../Change/Change.tsx";
import {useUser} from "../../lib/hooks/useUser.ts";
import "./MemeDisplay.css"


const MemeDisplay= ({toggleItemFavorite, memes, onSortColumn, sortColumn, sortType, height}) => {

    const {isAuth} = useUser();

    const FavoriteButtonCell = ({ rowData, dataKey, ...props }) => {

        const { id, favorite } = rowData;
        const handleFavClick = () => toggleItemFavorite(id, !favorite);

        return (
            <Table.Cell {...props} onClick={() => handleFavClick()}>
                {rowData[dataKey] ? <AiFillHeart color={"#9381FF"} /> : <AiOutlineHeart color={"#D3CFEB"} />}
            </Table.Cell>
        );
    }

    const ImageCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} >
            <img className="meme-display__image" src={rowData[dataKey] ? rowData[dataKey] : <Doge />} alt={rowData[dataKey]} />
        </Table.Cell>
    );

    const NameLinkCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props}>
            <Link to={`meme/${rowData['id']}`}>{rowData[dataKey]}</Link>
        </Table.Cell>
    );

    const PriceCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props}>
            {Math.round(rowData[dataKey] * 100) / 100}
        </Table.Cell>
    );

    const ButtonCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            <Link to={"/meme/" + rowData[dataKey]}>
                <span>Buy</span><span>/</span><span>Sell</span>
            </Link>
        </Table.Cell>
    );

    const ChangeCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            <Change value={rowData[dataKey]} />
        </Table.Cell>
    );

    const MarketCapCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            {rowData[dataKey]}
        </Table.Cell>
    );

    const memeTypes = [
        "general",
        "wholesome",
        "edgy",
        "dank",
        "ironic",
        "deep"
    ];

    const randomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const MemeType = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            {memeTypes[randomInt(0, 5)]}
        </Table.Cell>
    );

    return <Table
        onSortColumn={onSortColumn}
        data={memes}
        rowHeight={68}
        height={height ? height : 70 * memes?.length + 30}
        virtualized={true}
        sortColumn={sortColumn}
        sortType={sortType}
        style={{minWidth:"550px"}}
    >
        {isAuth &&
            <Table.Column align='center' verticalAlign='center' width={70}>
                <Table.HeaderCell></Table.HeaderCell>
                <FavoriteButtonCell dataKey='favorite' />
            </Table.Column>
        }
        <Table.Column align='center' verticalAlign='center' width={65}>
            <Table.HeaderCell></Table.HeaderCell>
            <ImageCell dataKey='picture' />
        </Table.Column>
        <Table.Column sortable align='left' verticalAlign='center' flexGrow={6}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <NameLinkCell dataKey='title' />
        </Table.Column>
        <Table.Column sortable align='left' verticalAlign='center' flexGrow={2}>
            <Table.HeaderCell>Market cap</Table.HeaderCell>
            <MarketCapCell dataKey="market_cap"/>
        </Table.Column>
        <Table.Column sortable align='left' verticalAlign='center' flexGrow={2}>
            <Table.HeaderCell>Change</Table.HeaderCell>
            <ChangeCell dataKey="fluctuation"/>
        </Table.Column>
        <Table.Column sortable align='left' verticalAlign='center' flexGrow={2}>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <PriceCell dataKey={"current_price"}/>
        </Table.Column>
        <Table.Column sortable align='left' verticalAlign='center' flexGrow={2}>
                <Table.HeaderCell>meme type</Table.HeaderCell>
                <MemeType dataKey="meme_type"/>
        </Table.Column>
        {isAuth &&
            <Table.Column align='center' verticalAlign='center' width={100}>
                <Table.HeaderCell></Table.HeaderCell>
                <ButtonCell dataKey={"id"} />
            </Table.Column>
        }
    </Table>
}

export default MemeDisplay;
