import React, {useState} from "react";
import "./AvatarSelector.css"
import {Button, Input, InputPicker, Popover, SelectPicker, Whisper} from "rsuite";
import ColorPicker from "rsuite-color-picker";
import KeyValuePairDisplay from "../KeyValuePairDisplay/KeyValuePairDisplay.tsx";
import AvatarDisplay from "../AvatarDisplay/AvatarDisplay.tsx";
import {Avatar} from "../../core/store/user.ts";

interface AvatarSelectorProps {
    onAvatarSelect: (avatar: Avatar ) => void,
    initialState?: Avatar
}

// Нужно брать инфу с сервера или я хз

// Clothing
const shirts = ["0", "1", "2"].map((item) => ({ label: item, value: item }));
const pants = ["0", "1", "2", "3"].map((item) => ({ label: item, value: item }));
const boots = ["0", "1"].map((item) => ({ label: item, value: item }));
const jacket = ["0"].map((item) => ({ label: item, value: item }));

// Character
const hairs = ["0", "1", "2", "3", "4", "5"].map((item) => ({ label: item, value: item }));
const mouths = ["0", "1"].map((item) => ({ label: item, value: item }));
const eyebrows = ["0", "1", "2"].map((item) => ({ label: item, value: item }));
const fists = ["0", "1"].map((item) => ({ label: item, value: item }));

// Misc
const chairs = ["0", "1", "2", "3", "4"].map((item) => ({ label: item, value: item }));
const notebooks = ["0", "1", "2", "3"].map((item) => ({ label: item, value: item }));
const tables = ["0", "1", "2", "3", "4", "5"].map((item) => ({ label: item, value: item }));

const miscLeft = ["0", "3", "7"].map((item) => ({ label: item, value: item }));
const miscRight = ["0", "1", "2", "7"].map((item) => ({ label: item, value: item }));
const background = ["0"].map((item) => ({ label: item, value: item }));
const face = ["0"].map((item) => ({ label: item, value: item }));


const defaultAvatarState: Avatar = {
    character: {
        current_skin_color: "#0", // HEX
        current_haircut: hairs[0].value, // NUMBER
        current_hair_color: "#5a2e1f", // HEX
        current_eyebrow_type: eyebrows[0].value, // number,
        current_eyebrow_color: "#5a2e1f", // HEX
        current_eye_color: "#2654b8", // HEX
        current_face_type: face[0].value, // number
        current_shirt: shirts[0].value, // number
        current_jacket: jacket[0].value, // number
        current_pants: pants[0].value, // number
        current_sneakers: boots[0].value// number
    },
    misc: {
        current_background: background[0].value,
        current_notebook: notebooks[0].value,
        current_table: tables[0].value,
        current_chair: chairs[0].value,
        current_misc_left: miscLeft[0].value,
        current_misc_right: miscRight[0].value,
    }
};

const AvatarSelector:React.FC<AvatarSelectorProps> = (
        {
            onAvatarSelect,
            initialState = defaultAvatarState
        }
    ) => {

    const [avatarState, setAvatarState] = useState<Avatar>(initialState);

    const updateAvatarState = (field: keyof Avatar["misc"] | keyof Avatar["character"], value: number | string) => {
        let updatedState: Avatar;

        if (Object.keys(avatarState.character).includes(field)) {
            updatedState = {
                ...avatarState,
                character: {
                    ...avatarState.character,
                    [field]: value
                }
            };
        } else if (Object.keys(avatarState.misc).includes(field)) {
            updatedState = {
                ...avatarState,
                misc: {
                    ...avatarState.misc,
                    [field]: value
                }
            };
        } else {
            console.error("Invalid field name");
            return;
        }

        setAvatarState(updatedState);
    };


    return (
        <div className="avatar-selector">
            <div className="avatar-selector__left-column">
                <div className="avatar-selector__section">
                    <div className="avatar-selector__column">
                        <h4>Colors</h4>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Skin color hex"} value={
                                <ColorPicker value={avatarState.character.current_skin_color}
                                             disableAlpha
                                             onChangeComplete={(val) => updateAvatarState("current_skin_color", val.hex)}
                                />
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Hair color hex"} value={
                                <ColorPicker value={avatarState.character.current_hair_color}
                                             disableAlpha
                                             onChangeComplete={(val) => updateAvatarState("current_hair_color", val.hex)}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Eyebrow color hex"} value={
                                <ColorPicker value={avatarState.character.current_eyebrow_color}
                                             disableAlpha
                                             onChangeComplete={(val) => updateAvatarState("current_eyebrow_color", val.hex)}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Eye color hex"} value={
                                <ColorPicker value={avatarState.character.current_eye_color}
                                             disableAlpha
                                             onChangeComplete={(val) => updateAvatarState("current_eye_color", val.hex)}/>
                            }/>
                        </div>
                        <h4>Misc</h4>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Background"} value={
                                <InputPicker value={avatarState.misc.current_background}
                                             onChange={(value) => updateAvatarState("current_background", value)}
                                             data={background} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Notebook"} value={
                                <InputPicker value={avatarState.misc.current_notebook}
                                             onChange={(value) => updateAvatarState("current_notebook", value)}
                                             data={notebooks} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Table"} value={
                                <InputPicker value={avatarState.misc.current_table}
                                             onChange={(value) => updateAvatarState("current_table", value)}
                                             data={tables} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Chair"} value={
                                <InputPicker value={avatarState.misc.current_chair}
                                             onChange={(value) => updateAvatarState("current_chair", value)}
                                             data={chairs} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Misc Left"} value={
                                <InputPicker value={avatarState.misc.current_misc_left}
                                             onChange={(value) => updateAvatarState("current_misc_left", value)}
                                             data={miscLeft} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Misc Right"} value={
                                <InputPicker value={avatarState.misc.current_misc_right}
                                             onChange={(value) => updateAvatarState("current_misc_right", value)}
                                             data={miscRight} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                    </div>
                    <div className="avatar-selector__column">
                        <h4>Character</h4>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Haircut"} value={
                                <InputPicker value={avatarState.character.current_haircut}
                                             onChange={(value) => updateAvatarState("current_haircut", value)}
                                             data={hairs} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Eyebrow type"} value={
                                <InputPicker value={avatarState.character.current_eyebrow_type}
                                             onChange={(value) => updateAvatarState("current_eyebrow_type", value)}
                                             data={eyebrows} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Face type"} value={
                                <InputPicker value={avatarState.character.current_face_type}
                                             onChange={(value) => updateAvatarState("current_face_type", value)}
                                             data={face} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <h4>Clothing</h4>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Shirt"} value={
                                <InputPicker value={avatarState.character.current_shirt}
                                             onChange={(value) => updateAvatarState("current_shirt", value)}
                                             data={shirts} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Jacket"} value={
                                <InputPicker value={avatarState.character.current_jacket}
                                             onChange={(value) => updateAvatarState("current_jacket", value)}
                                             data={jacket} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Pants"} value={
                                <InputPicker value={avatarState.character.current_pants}
                                             onChange={(value) => updateAvatarState("current_pants", value)}
                                             data={pants} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                        <div className="avatar-selector__row">
                            <KeyValuePairDisplay label={"Sneakers"} value={
                                <InputPicker value={avatarState.character.current_sneakers}
                                             onChange={(value) => updateAvatarState("current_sneakers", value)}
                                             data={boots} style={{width: 224}} cleanable={false}/>
                            }/>
                        </div>
                    </div>
                </div>
                <div className="avatar-selector__section">

                </div>
            </div>
            <div className="avatar-selector__right-column">
                <AvatarDisplay size={"xl"} avatar={avatarState}/>
                <Button appearance="primary" style={{width: "100%"}} onClick={() => onAvatarSelect(avatarState)}>Save
                    avatar</Button>
            </div>
        </div>
    );

};

export default AvatarSelector;
