import React from "react";
import "./Title.css";


interface TitleProps {
    size?: "s" | "m" | "l";
    appearence?: "primary" | "ghost" | "default"
    weight?: "normal" | "bold"
};

const Title: React.FC<React.PropsWithChildren<TitleProps>> = ({
    size = "m",
    appearence = "default",
    weight = "bold",
    children,
    ...props
}) => {
    const className = "title title__" + size + " title__" + appearence + " title__" + weight;
    return (<span {...props} className={className}>{children}</span>)
};

export default Title;
