import React, {SVGProps} from 'react';

const Doge = (props: SVGProps<SVGSVGElement>) => {
    return <svg {...props} width="127" height="143" viewBox="0 0 127 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M37.4646 26.4471C28.1942 32.47 8.71143 47.7678 4.94333 60.7758C6.95079 58.7681 12.652 58.7275 19.3972 74.6268C24.0144 76.8351 34.2124 81.372 38.0668 81.8538C42.8848 82.456 56.1342 83.0583 69.9859 77.6381C73.0167 76.4521 75.8456 75.1797 78.5863 73.947C88.3715 69.5459 97.0314 65.6509 109.734 68.0027C125.995 71.0133 126.285 83.0595 125.995 84.8656C125.87 77.6898 124.665 75.2808 124.665 75.2808L120.738 65.984C120.045 62.6307 118.888 57.0419 116.961 54.1511C119.972 50.7383 125.995 41.263 125.995 30.6635C125.995 29.0061 126.004 27.3205 126.014 25.636C126.08 13.8546 126.145 2.13186 122.984 0.551126C119.935 -0.973416 118.172 0.932497 116.248 3.01239C115.892 3.39738 115.531 3.78834 115.154 4.16461C112.745 6.57359 94.678 23.4359 93.4735 23.4359C93.2557 23.4359 92.723 23.3965 91.9465 23.3391C88.428 23.0791 79.9042 22.4491 72.9971 23.4359C68.0882 24.1371 67.4663 23.8177 65.783 22.9529C64.5751 22.3324 62.8206 21.4311 58.5432 20.4246C54.3275 13.8001 44.812 0.551126 40.4758 0.551126C36.8498 0.551126 37.2667 10.7929 37.5793 18.4748C37.7341 22.276 37.8633 25.4503 37.4646 26.4471Z"
              fill="url(#paint0_linear_1869_6361)"/>
        <path
            d="M40.4754 0.550781C44.8116 0.550781 54.3271 13.7998 58.5428 20.4243C50.4523 18.937 41.1193 23.8196 37.4642 26.4468C37.8629 25.45 37.7337 22.2756 37.5789 18.4745C37.2662 10.7925 36.8494 0.550781 40.4754 0.550781Z"
            fill="#343434"/>
        <path
            d="M40.4754 0.550781C44.8116 0.550781 54.3271 13.7998 58.5428 20.4243C50.4523 18.937 41.1193 23.8196 37.4642 26.4468C37.8629 25.45 37.7337 22.2756 37.5789 18.4745C37.2662 10.7925 36.8494 0.550781 40.4754 0.550781Z"
            fill="url(#paint1_linear_1869_6361)"/>
        <path
            d="M15.1815 119.193C-6.01756 95.5851 -0.476886 70.4112 4.94333 60.7752C6.95079 58.7675 12.652 58.7269 19.3972 74.6263C24.0144 76.8345 34.2124 81.3714 38.0668 81.8532C42.8848 82.4555 56.1342 83.0577 69.9859 77.6375C73.0167 76.4515 75.8456 75.1791 78.5863 73.9464C88.3714 69.5453 97.0314 65.6503 109.734 68.0022C125.995 71.0128 126.284 83.059 125.995 84.8651C123.586 99.9212 122.984 103.535 112.143 118.591C101.303 133.647 82.0308 140.272 76.0083 141.476C69.9859 142.681 41.6804 148.703 15.1815 119.193Z"
            fill="url(#paint2_linear_1869_6361)"/>
        <path
            d="M39.4205 77.1188C39.4205 71.819 29.7846 70.8956 24.9666 71.0963C22.1561 71.0963 16.4147 72.3008 15.9329 77.1188C15.3306 83.1413 22.5576 87.9593 25.5688 87.9593C28.5801 87.9593 39.4205 83.7435 39.4205 77.1188Z"
            fill="#343434"/>
        <path
            d="M23.7622 93.3795C21.3532 92.8977 17.5389 95.9892 15.9329 97.5952C13.5238 99.6598 27.3757 104.22 41.2274 104.822C52.3087 105.304 59.4955 101.008 61.7038 98.7997C59.6963 99.8034 53.3928 101.811 44.2386 101.811C32.7959 101.811 26.7735 93.9817 23.7622 93.3795Z"
            fill="#343434"/>
        <path
            d="M61.1017 57.8469V59.6537C62.9084 62.2634 67.6059 67.4829 71.9421 67.4829C77.3623 67.4829 83.987 61.4604 83.987 59.0514C83.987 56.6424 77.3623 51.2222 71.9421 50.62C67.6059 50.1382 62.9084 55.2372 61.1017 57.8469Z"
            fill="#343434"/>
        <path
            d="M30.9893 47.0065C28.0985 46.5247 24.1639 51.6237 22.5579 54.2334C22.5579 56.0402 26.7736 60.2559 30.3871 60.2559C34.0006 60.2559 36.4095 56.0402 36.4095 54.2334C36.4095 52.4267 34.6028 47.6087 30.9893 47.0065Z"
            fill="#343434"/>
        <path
            d="M69.3035 59.1923C68.8217 56.3015 71.1103 55.1773 72.3148 54.9765C72.3148 54.3743 65.69 53.7721 66.2922 59.1923C66.774 63.5284 70.9095 63.8095 72.917 63.408C71.9132 63.2073 69.7853 62.0831 69.3035 59.1923Z"
            fill="white"/>
        <path
            d="M28.3508 53.7723C28.3508 52.3269 29.5553 51.1625 30.1576 50.761C28.3508 50.3595 24.8578 50.3997 25.3396 53.7723C25.8214 57.1449 28.3508 57.185 29.5553 56.7835C29.1538 56.382 28.3508 55.2177 28.3508 53.7723Z"
            fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_1869_6361" x1="68.0539" y1="21.0786" x2="77.0876"
                            y2="77.0876"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#FCC96D"/>
                <stop offset="1" stopColor="#EAAD2B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1869_6361" x1="48.7639" y1="4.37345" x2="50.5323"
                            y2="14.4858"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#FCC96D"/>
                <stop offset="1" stopColor="#EAAD2B"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1869_6361" x1="63.8381" y1="75.2814" x2="70.4629"
                            y2="122.859"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFEBC0"/>
                <stop offset="1" stopColor="#FFE0A5"/>
            </linearGradient>
        </defs>
    </svg>
}

export default Doge;
