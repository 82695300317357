import React, { useState } from 'react'
import { Sidenav, Nav } from 'rsuite'
import { Link } from "react-router-dom"
import {useUser} from "../../lib/hooks/useUser.ts";
import {
	AiOutlineLineChart,
	AiFillFire,
	AiOutlineShoppingCart,
	AiOutlineUser,
	AiOutlineSetting,
	AiOutlineDatabase,
} from 'react-icons/ai'
import './Navbar.css'

const NavLink = React.forwardRef((props, ref) => {
	const { href, as, ...rest } = props;
	return (
		<Link to={href} as={as} >
			<a ref={ref} {...rest} />
		</Link>
	);
});

export const Navbar = () => {

	const [activeKey, setActiveKey] = useState(0);

	const {isAuth} = useUser();

	const links = [
		{ label: "Market", href: "/", icon: AiOutlineLineChart },
		{ label: "Leaderboards", href: "/leaderboards", icon: AiFillFire },
		{ label: "Shop", href: "/shop", icon: AiOutlineShoppingCart },
		{ label: "Profile", href: "/profile", icon: AiOutlineUser },
		{ label: "Settings", href: "/settings", icon: AiOutlineSetting },
		{ label: "Base", href: "/base", icon: AiOutlineDatabase },
	];

	return (

		<Sidenav>
			<Sidenav.Header className='navbar__header'>
				<svg width="242" height="36" viewBox="0 0 242 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 35.2084H46.4635V0.791016H28.1075L23.2317 16.2788L18.0691 0.791016H0V35.2084Z"
						  fill="#9381FF"/>
					<path
						d="M55.6416 28.1491V8.76463H60.1554L68.1862 21.9461H65.8046L73.6138 8.76463H78.1276L78.183 28.1491H73.143L73.0877 16.4354H73.9461L68.1031 26.2384H65.6662L59.657 16.4354H60.7093V28.1491H55.6416Z"
						fill="#352E5B"/>
					<path
						d="M89.6781 28.3984C87.9058 28.3984 86.355 28.0661 85.0258 27.4015C83.715 26.7184 82.6904 25.7953 81.952 24.6322C81.232 23.4507 80.872 22.1123 80.872 20.6169C80.872 19.1215 81.2227 17.7923 81.9243 16.6292C82.6443 15.4477 83.632 14.5338 84.8873 13.8877C86.1427 13.2231 87.555 12.8908 89.1242 12.8908C90.6012 12.8908 91.9488 13.1954 93.1673 13.8046C94.3858 14.3954 95.355 15.2723 96.075 16.4354C96.795 17.5984 97.155 19.0107 97.155 20.6723C97.155 20.8569 97.1457 21.0692 97.1273 21.3092C97.1088 21.5492 97.0904 21.7707 97.0719 21.9738H85.192V19.2046H94.275L92.2811 19.98C92.2996 19.2969 92.1704 18.7061 91.8935 18.2077C91.635 17.7092 91.2658 17.3215 90.7858 17.0446C90.3242 16.7677 89.7796 16.6292 89.1519 16.6292C88.5242 16.6292 87.9704 16.7677 87.4904 17.0446C87.0289 17.3215 86.6689 17.7184 86.4104 18.2354C86.1519 18.7338 86.0227 19.3246 86.0227 20.0077V20.8107C86.0227 21.5492 86.1704 22.1861 86.4658 22.7215C86.7796 23.2569 87.2227 23.6722 87.795 23.9676C88.3673 24.2446 89.0504 24.383 89.8442 24.383C90.5827 24.383 91.2104 24.2815 91.7273 24.0784C92.2627 23.8569 92.7888 23.5246 93.3058 23.0815L96.075 25.9615C95.355 26.7553 94.4688 27.3645 93.4165 27.7892C92.3642 28.1953 91.1181 28.3984 89.6781 28.3984Z"
						fill="#352E5B"/>
					<path
						d="M119.132 12.8908C120.295 12.8908 121.328 13.1308 122.233 13.6108C123.156 14.0723 123.876 14.7923 124.393 15.7708C124.928 16.7492 125.196 18.0138 125.196 19.5646V28.1491H119.935V20.423C119.935 19.3523 119.732 18.5769 119.325 18.0969C118.919 17.5984 118.365 17.3492 117.664 17.3492C117.165 17.3492 116.713 17.4692 116.307 17.7092C115.901 17.9492 115.587 18.3184 115.365 18.8169C115.144 19.2969 115.033 19.9246 115.033 20.7V28.1491H109.772V20.423C109.772 19.3523 109.568 18.5769 109.162 18.0969C108.775 17.5984 108.221 17.3492 107.501 17.3492C106.984 17.3492 106.522 17.4692 106.116 17.7092C105.729 17.9492 105.415 18.3184 105.175 18.8169C104.953 19.2969 104.842 19.9246 104.842 20.7V28.1491H99.5808V13.14H104.593V17.3215L103.596 16.1307C104.15 15.06 104.907 14.2569 105.867 13.7215C106.827 13.1677 107.898 12.8908 109.079 12.8908C110.427 12.8908 111.608 13.2415 112.624 13.9431C113.658 14.6261 114.35 15.6969 114.701 17.1554L112.984 16.7954C113.519 15.5769 114.322 14.6261 115.393 13.9431C116.482 13.2415 117.728 12.8908 119.132 12.8908Z"
						fill="#352E5B"/>
					<path
						d="M136.571 28.3984C134.799 28.3984 133.248 28.0661 131.919 27.4015C130.608 26.7184 129.583 25.7953 128.845 24.6322C128.125 23.4507 127.765 22.1123 127.765 20.6169C127.765 19.1215 128.116 17.7923 128.817 16.6292C129.537 15.4477 130.525 14.5338 131.78 13.8877C133.035 13.2231 134.448 12.8908 136.017 12.8908C137.494 12.8908 138.842 13.1954 140.06 13.8046C141.279 14.3954 142.248 15.2723 142.968 16.4354C143.688 17.5984 144.048 19.0107 144.048 20.6723C144.048 20.8569 144.039 21.0692 144.02 21.3092C144.002 21.5492 143.983 21.7707 143.965 21.9738H132.085V19.2046H141.168L139.174 19.98C139.192 19.2969 139.063 18.7061 138.786 18.2077C138.528 17.7092 138.159 17.3215 137.679 17.0446C137.217 16.7677 136.672 16.6292 136.045 16.6292C135.417 16.6292 134.863 16.7677 134.383 17.0446C133.922 17.3215 133.562 17.7184 133.303 18.2354C133.045 18.7338 132.915 19.3246 132.915 20.0077V20.8107C132.915 21.5492 133.063 22.1861 133.359 22.7215C133.672 23.2569 134.115 23.6722 134.688 23.9676C135.26 24.2446 135.943 24.383 136.737 24.383C137.475 24.383 138.103 24.2815 138.62 24.0784C139.155 23.8569 139.682 23.5246 140.199 23.0815L142.968 25.9615C142.248 26.7553 141.362 27.3645 140.309 27.7892C139.257 28.1953 138.011 28.3984 136.571 28.3984Z"
						fill="#352E5B"/>
					<path d="M150.849 28.1491V13.1123H144.895V8.76463H162.258V13.1123H156.332V28.1491H150.849Z"
						  fill="#352E5B"/>
					<path
						d="M164.052 28.1491V13.14H169.064V17.5154L168.316 16.2692C168.759 15.1431 169.479 14.3031 170.476 13.7492C171.473 13.1769 172.682 12.8908 174.104 12.8908V17.6261C173.864 17.5892 173.652 17.5707 173.467 17.5707C173.301 17.5523 173.116 17.543 172.913 17.543C171.842 17.543 170.975 17.8384 170.31 18.4292C169.645 19.0015 169.313 19.943 169.313 21.2538V28.1491H164.052Z"
						fill="#352E5B"/>
					<path
						d="M185.495 28.1491V25.3522L185.135 24.6599V19.5092C185.135 18.6784 184.877 18.0415 184.36 17.5984C183.862 17.1369 183.058 16.9061 181.951 16.9061C181.231 16.9061 180.502 17.0261 179.763 17.2661C179.025 17.4877 178.397 17.8015 177.88 18.2077L176.108 14.6354C176.957 14.0815 177.972 13.6569 179.154 13.3615C180.354 13.0477 181.545 12.8908 182.726 12.8908C185.163 12.8908 187.046 13.4538 188.375 14.58C189.723 15.6877 190.397 17.4323 190.397 19.8138V28.1491H185.495ZM181.065 28.3984C179.865 28.3984 178.849 28.1953 178.018 27.7892C177.188 27.383 176.551 26.8292 176.108 26.1276C175.683 25.4261 175.471 24.6415 175.471 23.7738C175.471 22.8507 175.702 22.0569 176.163 21.3923C176.643 20.7092 177.372 20.1923 178.351 19.8415C179.329 19.4723 180.594 19.2877 182.145 19.2877H185.689V22.0292H182.865C182.015 22.0292 181.415 22.1676 181.065 22.4446C180.732 22.7215 180.566 23.0907 180.566 23.5522C180.566 24.0138 180.742 24.383 181.092 24.6599C181.443 24.9369 181.923 25.0753 182.532 25.0753C183.105 25.0753 183.622 24.9369 184.083 24.6599C184.563 24.3646 184.914 23.9215 185.135 23.3307L185.855 25.2692C185.578 26.303 185.034 27.0876 184.222 27.623C183.428 28.1399 182.375 28.3984 181.065 28.3984Z"
						fill="#352E5B"/>
					<path
						d="M200.443 28.3984C199.076 28.3984 197.83 28.0845 196.704 27.4568C195.597 26.8107 194.71 25.9153 194.046 24.7707C193.381 23.6076 193.049 22.223 193.049 20.6169C193.049 19.0292 193.381 17.663 194.046 16.5184C194.71 15.3554 195.597 14.46 196.704 13.8323C197.83 13.2046 199.076 12.8908 200.443 12.8908C201.735 12.8908 202.824 13.1677 203.71 13.7215C204.615 14.2754 205.298 15.1246 205.76 16.2692C206.221 17.4138 206.452 18.863 206.452 20.6169C206.452 22.4076 206.23 23.8753 205.787 25.0199C205.344 26.1645 204.68 27.0138 203.793 27.5676C202.907 28.1215 201.79 28.3984 200.443 28.3984ZM201.55 24.2169C202.141 24.2169 202.667 24.0784 203.129 23.8015C203.609 23.5246 203.987 23.1184 204.264 22.583C204.541 22.0292 204.68 21.3738 204.68 20.6169C204.68 19.86 204.541 19.223 204.264 18.7061C203.987 18.1707 203.609 17.7646 203.129 17.4877C202.667 17.2107 202.141 17.0723 201.55 17.0723C200.941 17.0723 200.396 17.2107 199.916 17.4877C199.455 17.7646 199.086 18.1707 198.809 18.7061C198.532 19.223 198.393 19.86 198.393 20.6169C198.393 21.3738 198.532 22.0292 198.809 22.583C199.086 23.1184 199.455 23.5246 199.916 23.8015C200.396 24.0784 200.941 24.2169 201.55 24.2169ZM204.846 28.1491V25.5738L204.873 20.6169L204.596 15.66V7.60156H209.858V28.1491H204.846Z"
						fill="#352E5B"/>
					<path
						d="M221.108 28.3984C219.335 28.3984 217.785 28.0661 216.455 27.4015C215.145 26.7184 214.12 25.7953 213.382 24.6322C212.662 23.4507 212.302 22.1123 212.302 20.6169C212.302 19.1215 212.652 17.7923 213.354 16.6292C214.074 15.4477 215.062 14.5338 216.317 13.8877C217.572 13.2231 218.985 12.8908 220.554 12.8908C222.031 12.8908 223.378 13.1954 224.597 13.8046C225.815 14.3954 226.785 15.2723 227.505 16.4354C228.225 17.5984 228.585 19.0107 228.585 20.6723C228.585 20.8569 228.575 21.0692 228.557 21.3092C228.538 21.5492 228.52 21.7707 228.501 21.9738H216.622V19.2046H225.705L223.711 19.98C223.729 19.2969 223.6 18.7061 223.323 18.2077C223.065 17.7092 222.695 17.3215 222.215 17.0446C221.754 16.7677 221.209 16.6292 220.582 16.6292C219.954 16.6292 219.4 16.7677 218.92 17.0446C218.458 17.3215 218.098 17.7184 217.84 18.2354C217.582 18.7338 217.452 19.3246 217.452 20.0077V20.8107C217.452 21.5492 217.6 22.1861 217.895 22.7215C218.209 23.2569 218.652 23.6722 219.225 23.9676C219.797 24.2446 220.48 24.383 221.274 24.383C222.012 24.383 222.64 24.2815 223.157 24.0784C223.692 23.8569 224.218 23.5246 224.735 23.0815L227.505 25.9615C226.785 26.7553 225.898 27.3645 224.846 27.7892C223.794 28.1953 222.548 28.3984 221.108 28.3984Z"
						fill="#352E5B"/>
					<path
						d="M231.01 28.1491V13.14H236.023V17.5154L235.275 16.2692C235.718 15.1431 236.438 14.3031 237.435 13.7492C238.432 13.1769 239.641 12.8908 241.063 12.8908V17.6261C240.823 17.5892 240.61 17.5707 240.426 17.5707C240.26 17.5523 240.075 17.543 239.872 17.543C238.801 17.543 237.933 17.8384 237.269 18.4292C236.604 19.0015 236.272 19.943 236.272 21.2538V28.1491H231.01Z"
						fill="#352E5B"/>
				</svg>
			</Sidenav.Header>
			<Sidenav.Body className='navbar__body'>
				<Nav className='navbar__inner-body' activeKey={activeKey}>
					{links.map((item, index) => {
						return <Nav.Item
							key={"navlink_" + index}
							className='navbar__link'
							onClick={() => setActiveKey(index)}
							active={activeKey === index}
							href={item.href}
							as={NavLink}
							icon={<item.icon className='navbar__item-icon'/>}
						>
							<p>{item.label}</p>
						</Nav.Item>
					})}
				</Nav>
			</Sidenav.Body>
		</Sidenav>
	)
}
