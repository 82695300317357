import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import { useUser } from "../../lib/hooks/useUser.ts";
import PageColumnsWrapper from "../../components/PageColumnsWrapper/PageColumnsWrapper.tsx";
import KeyValuePairDisplay from "../../components/KeyValuePairDisplay/KeyValuePairDisplay.tsx";
import Change from "../../components/Change/Change.tsx";
import AvatarDisplay from "../../components/AvatarDisplay/AvatarDisplay.tsx";
import {XpBar} from "../../components/XpBar/XpBar";
import Table from "rsuite/Table";
import Achievement from "../../components/Achievement/Achievement";
import {Pagination} from "rsuite";
import {getUserGraph, getUserMemes} from "../../core/api/MemeTradeApi";
import {useNotification} from "../../lib/hooks/useNotification.ts";
import "./PortfolioPage.css";

const PortfolioPage = () => {
    const {user, token} = useUser();
    const name = user.login;

    const [memePage, setMemePage] = useState(1);
    const [memeData, setMemeData] = useState([]);
    const [maximumPage, setMaximumPage] = useState(1);
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortBy, setSortBy] = useState("population");
    const [order, setOrder] = useState("asc");

    const {notify} = useNotification();

    const validateMemeData = (data) => {
        return data && Array.isArray(data.memes) && typeof data.amount_of_pages === 'number';
    };

    const validateGraphData = (data) => {
        return Array.isArray(data) && data.every(item => item.price !== undefined && item.dt !== undefined);
    };

    const fetchAndProcessMemes = async (page) => {
        try {
            setIsLoading(true);
            const memeData = await getUserMemes(page, 10, "", sortBy.toUpperCase(), order.toUpperCase());
            if (validateMemeData(memeData)) {
                setMaximumPage(memeData["amount_of_pages"]);
                setMemeData(memeData);
            } else {
                notify("error", "Invalid meme data format");
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            notify("error", err.message || "Failed to fetch memes");
        }
    };

    const fetchAndProcessGraphData = async () => {
        try {
            const graphData = await getUserGraph(token);
            setGraphData(graphData.coordinates);
        } catch (err) {
            notify("error", err.message || "Failed to fetch graph data");
        }
    };

    useEffect(() => {
        if (token) {
            fetchAndProcessMemes(memePage);
            fetchAndProcessGraphData();
        }
    }, [token, memePage, sortBy, order]);

    const onSortColumn = (sortBy, order) => {
        setSortBy(sortBy);
        setOrder(order);
    };

    const ImageCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props}>
            <img className="portfolio-page__meme-image" src={rowData[dataKey] || dogeIcon} alt={rowData[dataKey]} />
        </Table.Cell>
    );

    const ChangeCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            <Change value={rowData[dataKey]} />
        </Table.Cell>
    );

    const chartOptions = {
        chart: {
            type: 'area',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true
                },
                autoSelected: 'zoom'
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Portfolio Value',
            data: graphData.map(coord => coord.price)
        }],
        xaxis: {
            type: 'datetime',
            categories: graphData.map(coord => new Date(coord.dt).getTime())
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        },
        colors: ['#6C63FF']
    };

    return (
        <PageColumnsWrapper content={
            <div className="portfolio-page">
                <div className="portfolio-page__grid">
                    <PortfolioPageCard classSuffix={"portfolio-page__memes"}>
                        <div className="portfolio-page__table-container">
                            <Table
                                data={memeData.memes}
                                className="portfolio-page__table"
                                height={330}
                                onSortColumn={onSortColumn}
                            >
                                <Table.Column align="center" width={50}>
                                    <Table.HeaderCell>{""}</Table.HeaderCell>
                                    <ImageCell dataKey={"meme.picture"}></ImageCell>
                                </Table.Column>
                                <Table.Column width={200}>
                                    <Table.HeaderCell>Title</Table.HeaderCell>
                                    <Table.Cell dataKey="meme.title"/>
                                </Table.Column>
                                <Table.Column width={100}>
                                    <Table.HeaderCell>Price</Table.HeaderCell>
                                    <Table.Cell dataKey="meme.current_price"/>
                                </Table.Column>
                                <Table.Column width={100}>
                                    <Table.HeaderCell>Change</Table.HeaderCell>
                                    <ChangeCell dataKey="meme.fluctuation"/>
                                </Table.Column>
                                <Table.Column width={100} align={"center"}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell align={"center"} dataKey="amount"/>
                                </Table.Column>
                            </Table>
                            <Pagination
                                style={{alignSelf: 'center'}}
                                prev
                                last
                                next
                                first
                                ellipsis
                                boundaryLinks
                                maxButtons={10}
                                limit={10}
                                total={maximumPage * 10}
                                activePage={memePage}
                                onChangePage={setMemePage}
                            />
                        </div>
                    </PortfolioPageCard>
                    <PortfolioPageCard classSuffix={"portfolio-page__achievements"}>
                        <div className="portfolio-page__achievements-container">
                            {achievements.sort((item) => !item.isDone).map((item, index) => (
                                <Achievement key={index} isDone={item.isDone} text={item.text} picture={"https://cdn-icons-png.flaticon.com/512/8744/8744961.png"}/>
                            ))}
                        </div>
                    </PortfolioPageCard>
                    <PortfolioPageCard classSuffix={"portfolio-page__avatar"}>
                        <div className="portfolio-page__column-wrapper">
                            <h4 className="portfolio-page__login">{user.login}</h4>
                            <AvatarDisplay avatar={user.avatar} size={"m"}/>
                            <div style={{width: "80%"}}>
                                <XpBar width={200} progress={0.6} level={user.level}></XpBar>
                                <span>{`Level ${user.level}. 1000xp to level ${user.level + 1}`}</span>
                            </div>
                            <button className="portfolio-page__battlepass-button">
                                {dogeIcon}
                                <span className="portfolio-page__button-text">Level 10 Battle Pass</span>
                            </button>
                        </div>
                    </PortfolioPageCard>
                    <PortfolioPageCard classSuffix={"portfolio-page__chart"}>
                        <Chart options={chartOptions} series={chartOptions.series} type="area"/>
                    </PortfolioPageCard>
                    <PortfolioPageCard classSuffix={"portfolio-page__rose-chart"}>
                        <Chart options={radarChartData.options} series={radarChartData.series} type="radar" height={150} />
                        <KeyValuePairDisplay label={"meme_type_1"} value={"30%"} />
                        <KeyValuePairDisplay label={"meme_type_2"} value={"30%"} />
                        <KeyValuePairDisplay label={"meme_type_3"} value={"30%"} />
                        <KeyValuePairDisplay label={"meme_type_4"} value={"30%"} />
                        <KeyValuePairDisplay label={"meme_type_5"} value={"30%"} />
                        <KeyValuePairDisplay label={"meme_type_6"} value={"30%"} />
                    </PortfolioPageCard>
                    <PortfolioPageCard classSuffix={"portfolio-page__stats"}>
                        <KeyValuePairDisplay label={"Total balance"}
                                             value={<span> <Change value={user.leaderboard_fluctuation}/> {user.balance}</span>}/>
                        <KeyValuePairDisplay label={"Total stocks"}
                                             value={<span> <Change value={user.stock_fluctuation}/> {user.meme_stock}</span>}/>
                        <KeyValuePairDisplay label={"Rating"} value={user.place_in_leaderboard}/>
                        <KeyValuePairDisplay label={"Degradant rating"} value={"#placeholder"}/>
                    </PortfolioPageCard>
                </div>
            </div>
        } rightColumn={<></>}/>
    );
};

const achievements = [
    {picture: "url", text: "text", isDone: true},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: true},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: true},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: true},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: true},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
    {picture: "url", text: "text", isDone: false},
];

const radarChartData = {
    series: [{
        name: '% of your memes',
        data: [80, 90, 70, 85, 60, 69, 80, 100, 22, 34, 23, 54]
    }],
    options: {
        chart: {
            type: 'radar',
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: ['Video', 'Image', 'Text', 'GIF', 'Audio','Video', 'Image', 'Text', 'GIF', 'Audio', 'Text', 'GIF', 'Audio']
        },
        yaxis: {
            show: false
        },
        fill: {
            opacity: 0.1
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['#6C63FF']
        },
        markers: {
            size: 4,
            colors: ['#6C63FF'],
            strokeColor: '#fff',
            strokeWidth: 2
        }
    }
};

const userMemes = [
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Shreck",
            "picture": "https://m.media-amazon.com/images/M/MV5BZDE2ZjIxYzUtZTJjYS00OWQ0LTk2NGEtMDliYmI3MzMwYjhkXkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    },
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Rickroll",
            "picture": "string",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    },
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Meme_name",
            "picture": "string",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    },
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Meme_name",
            "picture": "string",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    },
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Meme_name",
            "picture": "string",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    },
    {
        "amount": 0,
        "is_favorite": true,
        "meme": {
            "id": 0,
            "title": "Meme_name",
            "picture": "string",
            "unavailable": false,
            "current_price": 100000,
            "amount_of_shares": 0,
            "current_free_float_amount": 0,
            "market_cap": 0,
            "thumbnail": "string",
            "fluctuation": 0,
            "meme_type": "VIDEO_CONTENT"
        }
    }
];


const dogeIcon = <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd_2539_7145)">
        <path
            d="M35.906 22.4501C37.1055 24.5826 38.3317 30.1538 33.6402 35.3784C27.7758 41.9092 21.5116 40.5764 20.1788 40.3098C18.846 40.0433 14.581 38.5772 12.1819 35.2451C9.78287 31.9131 9.64958 31.1134 9.11646 27.7814M35.906 22.4501C35.0721 19.5713 30.7604 16.1858 28.7088 14.8529C28.6206 14.6323 28.6492 13.9298 28.6834 13.0886C28.7526 11.3885 28.8449 9.12197 28.0424 9.12197C27.0828 9.12197 24.9769 12.0541 24.044 13.5201C23.0974 13.7428 22.7091 13.9423 22.4418 14.0796C22.0692 14.271 21.9316 14.3417 20.8452 14.1865C19.3166 13.9682 17.4303 14.1076 16.6516 14.1651C16.4797 14.1778 16.3618 14.1865 16.3137 14.1865C16.0471 14.1865 12.0487 10.4548 11.5155 9.92166C11.4323 9.83839 11.3522 9.75187 11.2734 9.66666C10.8477 9.20637 10.4576 8.78458 9.78287 9.12197C9.08321 9.4718 9.09771 12.0661 9.11229 14.6734C9.11437 15.0462 9.11646 15.4193 9.11646 15.786C9.11646 18.1318 10.4493 20.2288 11.1157 20.984C10.6892 21.6238 10.4333 22.8606 10.2797 23.6027L9.41075 25.6602C9.41075 25.6602 9.14418 26.1933 9.11646 27.7814M35.906 22.4501C35.4617 22.0058 34.2 21.9968 32.7073 25.5154C31.6854 26.0041 29.4285 27.0082 28.5755 27.1148C27.5093 27.2481 24.5771 27.3814 21.5116 26.1818C20.8409 25.9194 20.2148 25.6378 19.6083 25.365C17.4428 24.391 15.5263 23.529 12.7151 24.0495C9.11646 24.7158 9.05235 27.3817 9.11646 27.7814"
            stroke="white" stroke-width="8" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M28.7088 14.8529C30.7604 16.1858 35.0721 19.5713 35.906 22.4501C35.4617 22.0058 34.2 21.9968 32.7073 25.5154C31.6854 26.0041 29.4285 27.0082 28.5755 27.1148C27.5093 27.2481 24.5771 27.3814 21.5116 26.1818C20.8409 25.9194 20.2148 25.6378 19.6083 25.365C17.4428 24.391 15.5263 23.529 12.7151 24.0495C9.11646 24.7158 9.05235 27.3817 9.11646 27.7814C9.14418 26.1933 9.41075 25.6602 9.41075 25.6602L10.2797 23.6027C10.4333 22.8606 10.6892 21.6238 11.1157 20.984C10.4493 20.2288 9.11646 18.1318 9.11646 15.786C9.11646 15.4193 9.11437 15.0462 9.11229 14.6734C9.09771 12.0661 9.08321 9.4718 9.78287 9.12197C10.4576 8.78458 10.8477 9.20637 11.2734 9.66666C11.3522 9.75187 11.4323 9.83839 11.5155 9.92166C12.0487 10.4548 16.0471 14.1865 16.3137 14.1865C16.3618 14.1865 16.4797 14.1778 16.6516 14.1651C17.4303 14.1076 19.3166 13.9682 20.8452 14.1865C21.9316 14.3417 22.0692 14.271 22.4418 14.0796C22.7091 13.9423 23.0974 13.7428 24.044 13.5201C24.9769 12.0541 27.0828 9.12197 28.0424 9.12197C28.8449 9.12197 28.7526 11.3885 28.6834 13.0886C28.6492 13.9298 28.6206 14.6323 28.7088 14.8529Z"
              fill="url(#paint0_linear_2539_7145)"/>
        <path
            d="M28.0428 9.12109C27.0832 9.12109 24.9773 12.0532 24.0444 13.5192C25.8348 13.1901 27.9003 14.2706 28.7092 14.8521C28.621 14.6315 28.6496 13.929 28.6838 13.0877C28.753 11.3877 28.8453 9.12109 28.0428 9.12109Z"
            fill="#343434"/>
        <path
            d="M28.0428 9.12109C27.0832 9.12109 24.9773 12.0532 24.0444 13.5192C25.8348 13.1901 27.9003 14.2706 28.7092 14.8521C28.621 14.6315 28.6496 13.929 28.6838 13.0877C28.753 11.3877 28.8453 9.12109 28.0428 9.12109Z"
            fill="url(#paint1_linear_2539_7145)"/>
        <path
            d="M33.6402 35.3784C38.3317 30.1537 37.1055 24.5826 35.906 22.4501C35.4617 22.0058 34.2 21.9968 32.7073 25.5154C31.6854 26.0041 29.4285 27.0082 28.5755 27.1148C27.5093 27.2481 24.5771 27.3813 21.5116 26.1818C20.8409 25.9194 20.2148 25.6378 19.6083 25.365C17.4428 24.391 15.5263 23.529 12.7151 24.0495C9.11646 24.7157 9.05235 27.3816 9.11646 27.7813C9.64959 31.1134 9.78287 31.9131 12.1819 35.2451C14.581 38.5771 18.846 40.0432 20.1788 40.3098C21.5116 40.5763 27.7758 41.9092 33.6402 35.3784Z"
            fill="url(#paint2_linear_2539_7145)"/>
        <path
            d="M28.2759 26.0657C28.2759 24.8928 30.4084 24.6884 31.4747 24.7328C32.0966 24.7328 33.3673 24.9994 33.4739 26.0657C33.6072 27.3985 32.0078 28.4647 31.3414 28.4647C30.675 28.4647 28.2759 27.5318 28.2759 26.0657Z"
            fill="#343434"/>
        <path
            d="M31.7412 29.6643C32.2743 29.5576 33.1185 30.2418 33.4739 30.5972C34.007 31.0541 30.9415 32.0633 27.876 32.1966C25.4237 32.3032 23.8332 31.3525 23.3445 30.8638C23.7887 31.0859 25.1837 31.5302 27.2096 31.5302C29.742 31.5302 31.0748 29.7975 31.7412 29.6643Z"
            fill="#343434"/>
        <path
            d="M23.4777 21.8007V22.2005C23.0779 22.778 22.0383 23.9332 21.0786 23.9332C19.8791 23.9332 18.413 22.6003 18.413 22.0672C18.413 21.5341 19.8791 20.3346 21.0786 20.2013C22.0383 20.0946 23.0779 21.2231 23.4777 21.8007Z"
            fill="#343434"/>
        <path
            d="M30.1418 19.4016C30.7815 19.295 31.6523 20.4234 32.0077 21.001C32.0077 21.4008 31.0748 22.3338 30.2751 22.3338C29.4754 22.3338 28.9423 21.4008 28.9423 21.001C28.9423 20.6011 29.3421 19.5349 30.1418 19.4016Z"
            fill="#343434"/>
        <path
            d="M21.6628 22.0976C21.7694 21.4578 21.263 21.209 20.9964 21.1646C20.9964 21.0313 22.4625 20.898 22.3292 22.0976C22.2226 23.0572 21.3074 23.1194 20.8631 23.0305C21.0853 22.9861 21.5562 22.7373 21.6628 22.0976Z"
            fill="white"/>
        <path
            d="M30.7259 20.8981C30.7259 20.5782 30.4594 20.3205 30.3261 20.2317C30.7259 20.1428 31.499 20.1517 31.3924 20.8981C31.2857 21.6445 30.7259 21.6533 30.4594 21.5645C30.5482 21.4756 30.7259 21.218 30.7259 20.8981Z"
            fill="white"/>
    </g>
    <defs>
        <filter id="filter0_ddd_2539_7145" x="0.355657" y="0.250676" width="45.3917" height="49.0963"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2.37466"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.137255 0 0 0 0 0.752941 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2539_7145"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="0.593665"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.720833 0 0 0 0 0.713024 0 0 0 0 0.713024 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_2539_7145" result="effect2_dropShadow_2539_7145"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="0.593665"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.720833 0 0 0 0 0.713024 0 0 0 0 0.713024 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect2_dropShadow_2539_7145" result="effect3_dropShadow_2539_7145"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2539_7145" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2539_7145" x1="21.9392" y1="13.6648" x2="19.94" y2="26.06"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#FCC96D"/>
            <stop offset="1" stop-color="#EAAD2B"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2539_7145" x1="26.2085" y1="9.96708" x2="25.8171" y2="12.205"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#FCC96D"/>
            <stop offset="1" stop-color="#EAAD2B"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2539_7145" x1="22.8722" y1="25.6604" x2="21.4061" y2="36.1896"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFEBC0"/>
            <stop offset="1" stop-color="#FFE0A5"/>
        </linearGradient>
    </defs>
</svg>;

const PortfolioPageCard = ({children, classSuffix}) => {
    return (
        <div className={"portfolio-page-card" + (classSuffix ? " " + classSuffix : "")}>
            {children}
        </div>
    );
};

export default PortfolioPage;
