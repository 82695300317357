import React from 'react';
import "./Achievement.css"

const Achievement = ({ picture, text, isDone }) => {
    return (
        <div className={`achievement ${isDone ? 'achievement--done' : ''}`}>
            <img src={picture} alt="ach" className="achievement__image" />
            <p className="achievement__text">{text}</p>
        </div>
    );
};

export default Achievement;
