import React from 'react';
import { Modal } from 'rsuite'
import { useState } from 'react';
import Title from "../Title/Title.tsx";
import {useNotification} from "../../lib/hooks/useNotification.ts";

// @ts-ignore
import doge from '../../assets/img/icons/dogeIcon.png'
import './TransactionModal.css'
import {makeTransaction} from "../../core/api/MemeTradeApi";
import {Meme} from "../../core/types/Meme.ts";
import {useUser} from "../../lib/hooks/useUser.ts";

interface TransactionModalProps {
    isOpen: boolean,
    isSelling?: boolean,
    onClose: () => void,
    meme?: Meme
}


// @ts-ignore
const TransactionModal: React.FC<TransactionModalProps> = ({ isOpen = false, isSelling = false, onClose, meme }) => {

    const [quantityState, setQuantityState] = useState(1);
    const {token} = useUser();

    const {notify} = useNotification();

    const handleDecrease = () => {
        setQuantityState((count) => (count - 1 >= 0 ? count - 1 : 0))
    };

    const handleIncrease = () => {
        setQuantityState((count) => (count + 1))
    };

    const handleBuy = () => {
        makeTransaction(meme?.id, {operation: 'BUY', amount: quantityState}, token)
            .then(() => notify("success", "Bought it!"))
            .catch((error) => notify("error", "Error, something went wrong while buying"))
    };

    const handleSell = () => {
        makeTransaction(meme?.id, {operation: 'SELL', amount: quantityState}, token)
            .then(() => notify("success", "Sold it!"))
            .catch((error) => notify("error", "Error, something went wrong while selling"))
    };

    return <Modal size={"xs"} open={isOpen} onClose={onClose}>
            <div className="transaction-modal">
                <div className="transaction-modal__header">
                    <img
                        className="transaction-modal__image"
                        src={meme?.["picture"] ? meme["picture"] : doge}
                        alt="meme"
                    />
                    <h3 className="transaction-modal__title">
                        {meme?.title ? meme.title : "memename"}
                    </h3>
                </div>
                <div className="transaction-modal__quantity">
                    <button className="transaction-modal__quantity-btn" onClick={() => handleDecrease()}>-</button>
                    <input className="transaction-modal__quantity-input" type="text" readOnly
                           value={quantityState}/>
                    <button className="transaction-modal__quantity-btn" onClick={() => handleIncrease()}>+</button>
                </div>
                {
                    isSelling ? <button className="transaction-modal__sell-btn" onClick={() => handleSell()}>
                        <Title>{(quantityState * (meme?.current_price ? Number.parseFloat(meme.current_price) : 0) * 1.01).toFixed(2)}</Title>
                        </button> :
                        <button className="transaction-modal__buy-btn" onClick={() => handleBuy()}>
                            <Title>{(quantityState * (meme?.current_price ? Number.parseFloat(meme.current_price) : 0) * 0.99).toFixed(2)}</Title>
                        </button>
                }
            </div>
        </Modal>
};

export default TransactionModal;
