import React from 'react'
import Table from 'rsuite/Table'

import './LeaderboardsTable.css'
import AvatarDisplay from '../AvatarDisplay/AvatarDisplay.tsx';
import Change from "../Change/Change.tsx";


const LeaderboardsTable = ({ data, selectUser, onSortColumn, sortColumn, sortType }) => {

	const AvatarCell = ({ rowData, dataKey, ...props }) => (
		<Table.Cell align='center' verticalAlign='center' {...props}>
			<AvatarDisplay avatar={rowData["avatar"]} size='s' />
		</Table.Cell>
	);

	const ChangeCell = ({rowData, dataKey, ...props}) => (
		<Table.Cell verticalAlign="center" {...props}>
			<Change value={rowData[dataKey] ? rowData[dataKey] : 0}/>
		</Table.Cell>
	);

	const MemeStockCell = ({rowData, dataKey, ...props}) => (
		<Table.Cell verticalAlign="center" {...props}>
			{rowData[dataKey]}
		</Table.Cell>
	);

	return (
		<Table
			data={data}
			height={890}
			rowHeight={82}
			style={{ width: "100%" }}
			onRowClick={(rowData) => selectUser(rowData)}
			sortType={sortType}
			sortColumn={sortColumn}
			onSortColumn={onSortColumn}
		>
			<Table.Column
				width={100}
				verticalAlign="center"
				fixed
				align="center"
				sortable
			>
				<Table.HeaderCell>
					position
				</Table.HeaderCell>
				<Table.Cell dataKey='place_in_leaderboard' />
			</Table.Column>
			<Table.Column
				width={100}
				verticalAlign="center"
				fixed
				align="center"
			>
				<Table.HeaderCell>
				</Table.HeaderCell>
				<AvatarCell />
			</Table.Column>
			<Table.Column
				flexGrow={5}
				verticalAlign="center"
				fixed

			>
				<Table.HeaderCell>
					username
				</Table.HeaderCell>
				<Table.Cell dataKey='login' />
			</Table.Column>
			<Table.Column
				verticalAlign="center"
				fixed
				align="center"
				flexGrow={3}
				sortable
			>
				<Table.HeaderCell>
					meme stock
				</Table.HeaderCell>
				<MemeStockCell dataKey={"meme_stock"}/>
			</Table.Column>
			<Table.Column
				verticalAlign="center"
				fixed
				align="center"
				flexGrow={1}
			>
				<Table.HeaderCell>
					growth
				</Table.HeaderCell>
				<ChangeCell dataKey={"leaderboard_fluctuation"}/>
			</Table.Column>
		</Table>
	);
};

export default LeaderboardsTable;
