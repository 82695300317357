import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { RecoilRoot } from 'recoil'

import 'rsuite/dist/rsuite.min.css'
import './index.css'

import { App } from './App'

const rootStyle = document.documentElement.style;

rootStyle.setProperty("--vh", `${window.innerHeight * 0.01}px`);

window.addEventListener("resize", () => {
	rootStyle.setProperty("--vh", `${window.innerHeight * 0.01}px`);
});

ReactDOM.render(
	<RecoilRoot>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</RecoilRoot>,
	document.getElementById('root')
)
