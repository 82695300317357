import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import AvatarDisplay from "../AvatarDisplay/AvatarDisplay.tsx";
import { XpBar } from "../XpBar/XpBar";
import { UserState } from "../../core/store/user.ts";
import KeyValuePairDisplay from "../KeyValuePairDisplay/KeyValuePairDisplay.tsx";
import Change from "../Change/Change.tsx";
import { random } from "lodash";
import "./UserDisplay.css";

interface UserDisplayProps {
    user: UserState;
}

const UserDisplay: React.FC<UserDisplayProps> = ({ user }) => {
    const xpProgress = useMemo(() => random(1, true), []);
    const level = useMemo(() => user.level || 0, [user.level]);
    const balance = useMemo(() => user.balance || 0, [user.balance]);
    const leaderboardFluctuation = useMemo(() => user.leaderboard_fluctuation || 0, [user.leaderboard_fluctuation]);
    const placeInLeaderboard = useMemo(() => user.place_in_leaderboard || "undefined", [user.place_in_leaderboard]);
    const memeStock = useMemo(() => user["meme_stock"] || 0, [user["meme_stock"]]);

    return (
        <div className="user-display">
            <Link to='/profile'>
                <h3 className='user-display__name'>{user.login}</h3>
            </Link>
            <div className='user-display__avatar'>
                <AvatarDisplay avatar={user.avatar} isPremium={user.bp_active} />
            </div>
            <div className='user-display__xp'>
                <XpBar level={level} progress={xpProgress} width={260} />
            </div>
            <div className='user-display__info'>
                <KeyValuePairDisplay label={"Balance"} value={balance} />
                <KeyValuePairDisplay label={"Change"} value={<Change value={leaderboardFluctuation} />} />
                <KeyValuePairDisplay label={"Place in leaderboards"} value={placeInLeaderboard} />
                <KeyValuePairDisplay label={"Meme stock"} value={memeStock} />
            </div>
        </div>
    );
};

export default React.memo(UserDisplay);
