class InputValidationHelper {
    validatePhoneNumber(input) {
        const numbers = input.replace(/[^\d]/g, '')
        if (numbers.length !== 11) return false
        return true
    }

    validateEmail(input) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(input);
    }

    validatePassword(input) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;'"<>,.?\/\\~`-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;'"<>,.?\/\\~`-]{8,}$/;
        return regex.test(input);
    }

    passwordsMatch(password, confirmPassword) {
        return password === confirmPassword;
    }
}

export default new InputValidationHelper()
