import React, {useState} from 'react'
import { HiOutlineArrowsUpDown } from 'react-icons/hi2'
import {Button, Modal} from 'rsuite'
import UserDisplay from "../../UserDisplay/UserDisplay.tsx";
import "./LoggedUser.css"
import TransactionHistory from "../../TransactionHistory/TransactionHistory.tsx";
import {useUser} from "../../../lib/hooks/useUser.ts";

export const LoggedUser = () => {

	const {user} = useUser();
	const [showTransactions, setShowTransactions] = useState(false)

	return (
		<div className="logged-user__container">
			<UserDisplay user={user}></UserDisplay>
			<Button onClick={() => setShowTransactions(true)} className='logged-user__button' appearance='primary'>
				<HiOutlineArrowsUpDown /> Transactions
			</Button>
			<Modal size={"md"} open={showTransactions} onClose={() => setShowTransactions(false)}>
				<TransactionHistory />
			</Modal>
		</div>
	)
}
