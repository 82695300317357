import React from 'react'
import { LoggedUser } from './views/LoggedUser'
import { NonLoggedUser } from './views/NonLoggedUser'

export const UserInfo = ({ user, isAuth }) => {

	return (
		<>
			{isAuth ? <LoggedUser /> : <NonLoggedUser />}
		</>
	)
}
