import React, { useState } from "react";
// import PageColumnsWrapper from "../../components/PageColumnsWrapper/PageColumnsWrapper.tsx";
// import { Input, InputPicker } from "rsuite";

// import { ReactComponent as BodyTorso } from '../../assets/avatar/character/body/body-torso.svg';
// import { ReactComponent as Feets } from '../../assets/avatar/character/body/feets.svg';
// import { ReactComponent as ForearmLeftArm } from '../../assets/avatar/character/body/forearm-left-arm.svg';
// import { ReactComponent as ForearmRightArm } from '../../assets/avatar/character/body/forearm-right-arm.svg';
// import { ReactComponent as Head } from '../../assets/avatar/character/body/head.svg';
// import { ReactComponent as LegLeft } from '../../assets/avatar/character/body/leg-left.svg';
// import { ReactComponent as LegRight } from '../../assets/avatar/character/body/leg-right.svg';
// import { ReactComponent as NeckShadow } from '../../assets/avatar/character/body/neck-shadow.svg';
// import { ReactComponent as Neck } from '../../assets/avatar/character/body/neck.svg';
// import { ReactComponent as ShoulderLeftArm } from '../../assets/avatar/character/body/shoulder-left-arm.svg';
// import { ReactComponent as ShoulderRightArm } from '../../assets/avatar/character/body/shoulder-right-arm.svg';
// import { ReactComponent as Eyes } from '../../assets/avatar/character/body/eyes.svg';
// import { ReactComponent as LeftFist } from '../../assets/avatar/character/body/fist-left.svg';
// import { ReactComponent as EyePupils } from '../../assets/avatar/character/body/eye-pupils.svg';
// import AvatarDisplay from "../../components/AvatarDisplay/AvatarDisplay.tsx";

import "./TestPage.css"
// @ts-ignore
import TransactionModal from "../../components/TransactionModal/TransactionModal.tsx";
import {Button, RadioTile} from "rsuite";


interface TestPageProps {

};



// Clothing
const shirts = [0, 1, 2].map((item) => ({ label: item, value: item }));
const pants = [0, 1, 2, 3].map((item) => ({ label: item, value: item }));
const boots = [0, 1].map((item) => ({ label: item, value: item }));

// Character
const hairs = [0, 1, 2, 3, 4, 5].map((item) => ({ label: item, value: item }));
const mouths = [0, 1].map((item) => ({ label: item, value: item }));
const eyebrows = [0, 1, 2].map((item) => ({ label: item, value: item }));
const fists = [0, 1].map((item) => ({ label: item, value: item }));

// Misc
const chairs = [0, 1, 2, 3, 4].map((item) => ({ label: item, value: item }));
const pcs = [0, 1, 2, 3].map((item) => ({ label: item, value: item }));
const tables = [0, 1, 2, 3, 4, 5].map((item) => ({ label: item, value: item }));

const tableLeft = [0, 3, 7].map((item) => ({ label: item, value: item }));
const tableRight = [0, 1, 2, 7].map((item) => ({ label: item, value: item }));

const floorLeft = [0, 3].map((item) => ({ label: item, value: item }));
const floorRight = [0, 5, 6].map((item) => ({ label: item, value: item }));


type AvatarState = {
    shirt: number;
    pants: number;
    boots: number;
    hairs: number;
    mouth: number;
    eyebrows: number;
    chair: number;
    pc: number;
    table: number;
    tableLeft: number;
    tableRight: number;
    floorLeft: number;
    floorRight: number;
    skinColor: string;
    eyeColor: string;
};

const initialState: AvatarState = {
    shirt: 1,
    pants: 1,
    boots: 1,
    hairs: 1,
    mouth: 1,
    eyebrows: 1,
    chair: 1,
    pc: 1,
    table: 1,
    tableLeft: 3,
    tableRight: 1,
    floorLeft: 3,
    floorRight: 5,
    skinColor: "#D6AA8D",
    eyeColor: "#00abba",
};

const TestPage: React.FC<TestPageProps> = () => {

    // const [avatarState, setAvatarState] = useState<AvatarState>(initialState);
    //
    // const updateAvatarState = (field: keyof AvatarState, value: number | string) => {
    //     setAvatarState((prevState) => ({ ...prevState, [field]: value }));
    // };
    //
    //
    // const AvatarSelector: React.FC = () => {
    //
    //     return (
    //         <div>
    //             <div>Shirt: </div>
    //             <InputPicker value={avatarState.shirt} onChange={(value) => updateAvatarState("shirt", value)} data={shirts} />
    //             <div>Pants: </div>
    //             <InputPicker value={avatarState.pants} onChange={(value) => updateAvatarState("pants", value)} data={pants} />
    //             <div>Boots: </div>
    //             <InputPicker value={avatarState.boots} onChange={(value) => updateAvatarState("boots", value)} data={boots} />
    //             <div>Hair: </div>
    //             <InputPicker value={avatarState.hairs} onChange={(value) => updateAvatarState("hairs", value)} data={hairs} />
    //             <div>Mouth: </div>
    //             <InputPicker value={avatarState.mouth} onChange={(value) => updateAvatarState("mouth", value)} data={mouths} />
    //             <div>Eyebrows: </div>
    //             <InputPicker value={avatarState.eyebrows} onChange={(value) => updateAvatarState("eyebrows", value)} data={eyebrows} />
    //             <div>Chair: </div>
    //             <InputPicker value={avatarState.chair} onChange={(value) => updateAvatarState("chair", value)} data={chairs} />
    //             <div>Pc: </div>
    //             <InputPicker value={avatarState.pc} onChange={(value) => updateAvatarState("pc", value)} data={pcs} />
    //             <div>Table: </div>
    //             <InputPicker value={avatarState.table} onChange={(value) => updateAvatarState("table", value)} data={tables} />
    //             <div>Table left misc: </div>
    //             <InputPicker value={avatarState.tableLeft} onChange={(value) => updateAvatarState("tableLeft", value)} data={tableLeft} />
    //             <div>Table right misc: </div>
    //             <InputPicker value={avatarState.tableRight} onChange={(value) => updateAvatarState("tableRight", value)} data={tableRight} />
    //             <div>Floor left misc: </div>
    //             <InputPicker value={avatarState.floorLeft} onChange={(value) => updateAvatarState("floorLeft", value)} data={floorLeft} />
    //             <div>Floor right misc: </div>
    //             <InputPicker value={avatarState.floorRight} onChange={(value) => updateAvatarState("floorRight", value)} data={floorRight} />
    //             <div>Skin color hex:</div>
    //             <Input value={avatarState.skinColor} onChange={(value) => updateAvatarState("skinColor", value)} />
    //             <div>Eye color hex:</div>
    //             <Input value={avatarState.eyeColor} onChange={(value) => updateAvatarState("eyeColor", value)} />
    //         </div>
    //     );
    // };
    //
    //
    //
    //
    // return (
    //     <PageColumnsWrapper content={
    //         <>
    //             <AvatarDisplay avatar={avatarState} size="xs" />
    //             <AvatarDisplay avatar={avatarState} size="s" />
    //             <AvatarDisplay avatar={avatarState} size="m" />
    //             <AvatarDisplay avatar={avatarState} size="l" />
    //
    //         </>
    //     } rightColumn={<AvatarSelector />} />
    // );


    const [transactionOpen, setTransactionOpen] = useState(false);
    const [buying, setBuying] = useState(false);

    const onClose = () => closeModal()
    const openModal = () => setTransactionOpen(true);
    const closeModal = () => setTransactionOpen(false);
    const rowData = {}

    const toggleBuying = () => setBuying((b) => (!b));

    return (
        <>
            <Button onClick={toggleBuying}>ToggleBuying</Button>
            <span>{"Buying: " + buying}</span>
            <Button onClick={openModal}>OpenModal</Button>
            <TransactionModal isOpen={transactionOpen} isSelling={!buying} onClose={onClose} rowData={rowData}></TransactionModal>
        </>
    )

};

export default TestPage;