import axios from "axios";

const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000,
});

export const WizardApiServiceWithoutInterceptors = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000, // request timeout
});

// request interceptor
ApiService.interceptors.request.use(
  (config) => (config),
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
ApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiService;
