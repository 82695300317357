import React, { FocusEvent, FocusEventHandler, useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, InputGroup, useToaster, Message } from 'rsuite'
import { useRecoilState } from 'recoil'
//FIXME:
// import googleIcon from '../../assets/img/icons/icons8-google.svg'
// import redditIcon from '../../assets/img/icons/icons8-reddit.svg'
// import twitterIcon from '../../assets/img/icons/icons8-twitter.svg'
// import facebookIcon from '../../assets/img/icons/icons8-facebook.svg'

import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import EyeIcon from '@rsuite/icons/legacy/Eye';

import { AUTH_MODAL_STATE } from '../../core/store/app.ts'
import { useUser } from '../../lib/hooks/useUser.ts'
import { useNotification } from '../../lib/hooks/useNotification.ts'
import { registerUser } from '../../core/api/MemeTradeApi.js'
import inputValidationHelper from '../../core/helpers/inputValidationHelper.js';
import Title from "../../components/Title/Title.tsx";
import Doge from "../../assets/svg/Doge.tsx";
import "./AuthModal.css"


export const AuthModal: React.FC = () => {


    const [modalState, setModalState] = useRecoilState(AUTH_MODAL_STATE);

    const handleModalClose = () => setModalState(state => ({ ...state, isOpen: false}));
    const handleModalExited = () => setModalState(state => ({ ...state, type: "select"}));

    let modalContent;
    switch (modalState.type) {
        case 'login':
            modalContent = <LoginModal />;
            break;
        case 'signup':
            modalContent = <SignUpModal />;
            break;
        default:
            modalContent = <AuthSelectionModal />;
            break;
    }

    return (
        <Modal
            open={modalState.isOpen}
            onExited={handleModalExited}
            onClose={handleModalClose}
            size="xs"
        >
            {modalContent}
        </Modal>
    );
};

const AuthSelectionModal: React.FC = () => {

    const [modalState, setModalState] = useRecoilState(AUTH_MODAL_STATE);
    const handleLoginClick = () => setModalState(state => ({ ...state, type: "login" }));
    const handleSignupClick = () => setModalState(state => ({ ...state, type: "signup" }));

    return (
        <>

            <Modal.Body className="auth-modal__body">
                <div className="modal-content">
                    <Doge />
                    <Title>Wanna trade?</Title>
                    <Title size="s" appearence="ghost">Hop in!</Title>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    appearance='primary'
                    onClick={handleSignupClick}
                >
                    Sign Up
                </Button>
                <Button
                    appearance='ghost'
                    onClick={handleLoginClick}
                >
                    Login
                </Button>
            </Modal.Footer>
        </>
    );
};

const SignUpModal: React.FC = () => {

    const [message, setMessage] = useState<string>("");
    const [modalState, setModalState] = useRecoilState(AUTH_MODAL_STATE);
    const {notify} = useNotification();


    const emailRef = useRef<HTMLInputElement>(null);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const [pwdVisibleState, setPwdVisibleState] = useState(false);
    const [pwdConfVisibleState, setPwdConfVisibleState] = useState(false);

    const handleEmailBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {
        if (!inputValidationHelper.validateEmail(emailRef.current?.value)) {
            setMessage("Email is in wrong format");
        }
    };

    const handlePasswordBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {

        if (!passwordRef.current?.value) {
            setMessage("Password cannot be empty");
            return;
        }

        if (!inputValidationHelper.validatePassword(passwordRef.current?.value)) {
            setMessage("Password should be at least 8 symbols long, including 1 special character, 1 uppercase letter, 1 lowercase letter and 1 number");
            return;
        }
    };

    const handlePasswordConfirmBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {
        if (!inputValidationHelper.passwordsMatch(passwordRef.current?.value, confirmPasswordRef.current?.value)) {
            setMessage("Passwords don't match");
            return;
        }
    };

    const handleFocusEvent = () => {
        setMessage("")
    };

    const handleUsernameBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {
    }; // TODO: validate here

    const handleSignup = async () => {
        try {
            setMessage("");

            if (!emailRef.current?.value || !usernameRef.current?.value || !passwordRef.current?.value) {
                setMessage("All values must be filled in");
                return;
            }

            const resp = await registerUser(emailRef.current.value, usernameRef.current.value, passwordRef.current.value);

            notify("success", "You can login now!");
            setModalState(state => ({...state, type: "login"}));

        } catch (error) {
            notify("error", "Something went wrong while signing up, try again");
        }
    };

    return (
        <>
            <Modal.Body className='signup-modal__body'>
                <Input ref={emailRef} placeholder='Email' onFocus={handleFocusEvent} onBlur={handleEmailBlur}/>
                <Input ref={usernameRef} placeholder='Username' id="username_field" autoComplete="off"/>
                <InputGroup onFocus={handleFocusEvent} inside id="password_field">
                    <Input
                        placeholder='Password'
                        id="password_field"
                        type={pwdVisibleState ? 'text' : 'password'}
                        ref={passwordRef}
                        onBlur={handlePasswordBlur}
                        autoComplete="off"
                    />
                    <InputGroup.Button onClick={() => setPwdVisibleState(!pwdVisibleState)}>
                        {pwdVisibleState ? <EyeIcon/> : <EyeSlashIcon/>}
                    </InputGroup.Button>
                </InputGroup>
                <InputGroup onFocus={handleFocusEvent} inside id="password_field_confirmation">
                    <Input
                        placeholder='Confirm password'
                        id="password_field"
                        type={pwdConfVisibleState ? 'text' : 'password'}
                        ref={confirmPasswordRef}
                        onBlur={handlePasswordConfirmBlur}
                        autoComplete="off"
                    />
                    <InputGroup.Button onClick={() => setPwdConfVisibleState(!pwdConfVisibleState)}>
                        {pwdConfVisibleState ? <EyeIcon/> : <EyeSlashIcon/>}
                    </InputGroup.Button>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer className='signup-modal__footer'>
                {message ? <Message type='error'>{message}</Message> : ""}
                <span>
                    By clicking Register me you agree with <a href='/public'>terms</a>{' '}
                    and <a href='/public'>privacy</a> conditions
                </span>
                <Button appearance='primary' style={{width: "100%"}} onClick={handleSignup}>
                    Sign Up
                </Button>

            </Modal.Footer>
        </>
    );
};


const LoginModal: React.FC = () => {

    const [pwdVisibleState, setPwdVisibleState] = useState(false);
    const [modalState, setModalState] = useRecoilState(AUTH_MODAL_STATE);

    const {login} = useUser();
    const {notify} = useNotification();

    const handleLoginModalClose = () => setModalState(state => ({...state, isOpen: false}));

    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const handlePasswordBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {
    }; // TODO: validate here
    const handleUsernameBlur: FocusEventHandler<HTMLInputElement> = (e: FocusEvent) => {
    }; // TODO: validate here

    const handleLogin = async () => {
        try {
            if (!usernameRef.current?.value || !passwordRef.current?.value) return;
            const res = await login(usernameRef.current.value, passwordRef.current.value);
            if (res) {
                notify("success", "You have successfully logged in");
                handleLoginModalClose();
            }
        } catch (e) {
            notify("error", "Error encountered while logging in, try again");
        }
    };

    return (
        <>
            <Modal.Body className="login-modal__body">
                <Input
                    placeholder='Username'
                    ref={usernameRef}
                    onBlur={handleUsernameBlur}
                />
                <InputGroup inside>
                    <Input
                        placeholder='Password'
                        type={pwdVisibleState ? 'text' : 'password'}
                        ref={passwordRef}
                        onBlur={handlePasswordBlur}
                    />
                    <InputGroup.Button onClick={() => setPwdVisibleState(!pwdVisibleState)}>
                        {pwdVisibleState ? <EyeIcon/> : <EyeSlashIcon/>}
                    </InputGroup.Button>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer className='login-modal__footer'>
                <Button onClick={handleLogin} style={{width: "100%"}} appearance='primary'>
                    Login
                </Button>
            </Modal.Footer>
        </>
    );
}
