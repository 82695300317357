import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, ButtonGroup } from 'rsuite';
import PriceLineChart from '../../components/LineChart/PriceLineChart.tsx';
import { Meme } from '../../core/types/Meme.ts';
import { getMeme, getMemeGraph } from '../../core/api/MemeTradeApi.js';
import { DateFormatter } from '../../core/helpers/dateFormatter.ts';
import MemeInfo from '../../components/MemeInfo/MemeInfo.tsx';
import PageColumnsWrapper from '../../components/PageColumnsWrapper/PageColumnsWrapper.tsx';
import TransactionModal from "../../components/TransactionModal/TransactionModal.tsx";
import {useNotification} from "../../lib/hooks/useNotification.ts";
import {useUser} from "../../lib/hooks/useUser.ts";
import './MemePage.css'
import InfoPopup from "../../components/InfoPopup/InfoPopup.tsx";


const buttons = [
	{
		label: "Day",
		timeframe: "DAY",
		interval: "HOUR",
	},
	{
		label: "Week",
		timeframe: "WEEK",
		interval: "DAY",
	},
	{
		label: "Month",
		timeframe: "MONTH",
		interval: "DAY",
	},
	{
		label: "Year",
		timeframe: "YEAR",
		interval: "DAY",
	},
	{
		label: "All time",
		timeframe: "ALL_TIME",
		interval: "DAY",
	},
];

export const MemePage: React.FC = () => {

	const [memeData, setMemeData] = useState<Meme>();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const {notify} = useNotification();
	const [graph, setGraph] = useState([{
		dt: "",
		price: 0
	}]);

	const infoPopupContent = "<p>Here you can trade memes</p>";

	const {isAuth} = useUser();

	const { memeId } = useParams();

	const [modal, setModal] = useState(false);
	const [modalIsSelling, setModalIsSelling] = useState(false);

	const openTransactionModal = (isSelling: boolean) => {
		setModalIsSelling(isSelling)
		setModal(true);
	};

	const closeTransactionModal = () => setModal(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const graph = await getMemeGraph(memeId);
				setGraph(graph.coordinates);
				const memeData = await getMeme(memeId);
				setMemeData(memeData);
			} catch (error:any) {
				notify("error", error)
			}
			setIsLoading(false);
		};
		fetchData();
	}, []);

	return (
		<PageColumnsWrapper
			content={<div className="meme-page__graph">
				<div className="meme-page__header">
					<h3 className="meme-page__title">{memeData?.title} {<InfoPopup htmlContent={infoPopupContent}/>}</h3>
				</div>
				<PriceLineChart graph={graph}/>
				<TransactionModal onClose={closeTransactionModal} isOpen={modal} meme={memeData} isSelling={modalIsSelling}/>
			</div>
			}
			rightColumn={<div className="meme-page__info">
				<MemeInfo
					price={Number.parseFloat(memeData?.current_price || "")}
					freeFloat={memeData?.current_free_float_amount || 0}
					marketCap={memeData?.market_cap || 0}
					picture={memeData?.picture || ""}
					canMakeTransaction={isAuth}
					openModal={openTransactionModal}
				/>
			</div>} />
	);
};
