import { RecoilState, atom } from "recoil";

type TAuthModalState = {
    isOpen: boolean,
    type: "select" | "login" | "signup"
};

export const AUTH_MODAL_STATE = atom<TAuthModalState>({
    key: "authModalState",
    default: {
        isOpen: false,
        type: "select"
    }
});

export const SEARCH_DRAWER_STATE = atom({
    key: "searchDrawerState",
    default: false
});
