import React, { HTMLAttributes } from 'react';
import Chart from "react-apexcharts";



interface PriceLineChartProps extends HTMLAttributes<HTMLDivElement> {
  graph: {
    dt: string,
    price: number
  }[]
}

const PriceLineChart: React.FC<PriceLineChartProps> = ({
                                                         graph,
  ...props
}) => {
  const chartOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        autoSelected: 'zoom'
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    series: [{
      name: 'Portfolio Value',
      data: graph.map(coord => coord.price)
    }],
    xaxis: {
      type: 'datetime',
      categories: graph.map(coord => new Date(coord.dt).getTime())
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    colors: ['#6C63FF']
  };

  return (
    <div {...props}>
      <Chart options={chartOptions} series={chartOptions.series} type="area"/>
    </div>
  );
}

export default PriceLineChart;
