import React from 'react'

import './XpBar.css'

export const XpBar = ({ level = 1, progress = 0, width }) => {
	const filledXpBarLength = width * progress
	return (
		<div className='xpbarContainer'>
			<div className='xpbarLevelScale' style={{ width: `${width}px` }} />
			<div
				className='xpbarLevelScaleFilled'
				style={{ width: `${filledXpBarLength}px` }}
			/>
			<div className='xpbarLevel'>{level}</div>
		</div>
	)
}
