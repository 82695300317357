import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import { Infobar } from './components/Infobar/Infobar'
import { Navbar } from './modules/Navbar/Navbar'
import { PageLoader } from './components/PageLoader/PageLoader'
import { MainPage } from './pages/MainPage/MainPage'
import { MemePage } from './pages/MemePage/MemePage.tsx'
import { SettingsPage } from './pages/SettingsPage/SettingsPage'
import { ShopPage } from './pages/ShopPage/ShopPage'
import { SearchDrawer } from './modules/SearchDrawer/SearchDrawer.jsx'
import { AuthModal } from './modules/AuthModal/AuthModal.tsx'
import LeaderboardsPage from './pages/LeaderboardsPage/LeaderboardsPage.tsx'
import TestPage from './pages/TestPage/TestPage.tsx'
import NotAuthorizedPage from "./pages/NotAuthorizedPage/NotAuthorizedPage.tsx";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import {useUser} from "./lib/hooks/useUser.ts";
import "./App.css"


const PrivateRoute = ({ element, isAuthorized }) => {
    return isAuthorized ? element : <Navigate to="/not-authorized" />;
};


export const App = () => {

    const {isAuth} = useUser();

    return (
        <div className="app">
            <div className="sidebar">
                <Navbar />

            </div>
            <div className="navbar">
                <Infobar />
            </div>
            <div className="crfrontent">
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path={"/profile"} element={<PrivateRoute element={<PortfolioPage />} isAuthorized={isAuth}/>}/>
                    <Route path="/meme/:memeId" element={<MemePage />} />
                    <Route path="/settings" element={<PrivateRoute element={<SettingsPage />} isAuthorized={isAuth}/>} />
                    <Route path="/testLoader" element={<PageLoader message="Loading smth . . ." />} />
                    <Route path="/leaderboards" element={<LeaderboardsPage />} />
                    <Route path="/shop" element={<PrivateRoute element={<ShopPage />} isAuthorized={isAuth}/>} />
                    <Route path='/test' element={<TestPage />} />
                    <Route path="/not-authorized" element={<NotAuthorizedPage />} />
                </Routes>
                <AuthModal />
                <SearchDrawer />
            </div>
        </div>
    )
}
