import React from 'react'
import { Button } from 'rsuite'

import './ShopPack.css'

export const ShopPack = ({ title, subTitle, items, price, color = 'red' }) => {
	const mapPackItems = () => {
		return items.map((item) => {
			return (
				<div key={item.id} className='shopPackItem'>
					<div>{item.text}</div>
					{item.img && <img src={item.img} alt={item.img} />}
				</div>
			)
		})
	}

	return (
		<div className={`shopPackContainer ${color}`}>
			<div className='shopPackTitle'>
				{title}
				<div className='shopPackSubTitle'>{subTitle}</div>
			</div>
			<div className='shopPackItems'>{mapPackItems()}</div>
			<div className='shopPackBuy'>
				<Button className='shopPackButton'>Buy Now</Button>
				{price && <span>Only for {price}</span>}
			</div>
		</div>
	)
}
