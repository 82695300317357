import React, { useEffect, useState } from "react";
import "./TransactionHistory.css";
import Table from "rsuite/Table";
import { useNotification } from "../../lib/hooks/useNotification.ts";
import { getUserTransactions } from "../../core/api/MemeTradeApi";
import { Loader, Pagination } from "rsuite";
import { useUser } from "../../lib/hooks/useUser.ts";

interface TransactionHistoryTableProps {
    transactions: Transaction[];
    loading: boolean;
}

type Transaction = {
    buyer_id: number | null;
    seller_id: number | null;
    datetime: string;
    meme: number | null;
    price_of_share: number | null;
    amount: number | null;
    price_value: number | null;
};

const TransactionHistory: React.FC = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { notify } = useNotification();
    const [currentPage, setCurrentPage] = useState(1);
    const [maximumPage, setMaximumPage] = useState(0);
    const { token, loading: authLoading } = useUser();

    const fetchTransactionHistory = async () => {
        setLoading(true);
        try {
            const data = await getUserTransactions(token, currentPage, 5);
            setTransactions(data.transactions);
            setMaximumPage(data.amount_of_pages);
        } catch (err) {
            notify("error", "Error while loading transaction history");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!authLoading) {
            fetchTransactionHistory();
        }
    }, [currentPage, authLoading]);

    useEffect(() => {
        const handleResize = () => {
            window.dispatchEvent(new Event('resize'));
        };
        handleResize();
    }, []);

    if (authLoading) {
        return <Loader center content="Loading..." />;
    }

    return (
        <div className="transaction-history">
            <TransactionHistoryTable transactions={transactions} loading={loading} />
            <Pagination
                style={{ alignSelf: "center" }}
                prev
                last
                next
                first
                ellipsis
                boundaryLinks
                maxButtons={10}
                limit={5}
                total={5 * maximumPage}
                activePage={currentPage}
                onChangePage={setCurrentPage}
            />
        </div>
    );
};

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = ({ transactions, loading }) => {
    return (
        <div className="transaction-history-table">
            <Table data={transactions} height={300}>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>buyer id</Table.HeaderCell>
                    <Table.Cell dataKey="buyer_id" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>seller id</Table.HeaderCell>
                    <Table.Cell dataKey="seller_id" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>meme id</Table.HeaderCell>
                    <Table.Cell dataKey="meme" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>price</Table.HeaderCell>
                    <Table.Cell dataKey="price_of_share" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>amount</Table.HeaderCell>
                    <Table.Cell dataKey="amount" />
                </Table.Column>
                <Table.Column flexGrow={2} align={"end"}>
                    <Table.HeaderCell>time</Table.HeaderCell>
                    <Table.Cell dataKey="datetime" />
                </Table.Column>
            </Table>
        </div>
    );
};

export default TransactionHistory;
