import React, { useState, useEffect } from 'react';
import {hexToCSSFilter} from "hex-to-css-filter";
import { Avatar } from '../../core/store/user';
import { ReactComponent as BodyTorso } from '../../assets/avatar/character/body/body-torso.svg';
import { ReactComponent as Feets } from '../../assets/avatar/character/body/feets.svg';
import { ReactComponent as ForearmLeftArm } from '../../assets/avatar/character/body/forearm-left-arm.svg';
import { ReactComponent as ForearmRightArm } from '../../assets/avatar/character/body/forearm-right-arm.svg';
import { ReactComponent as Head } from '../../assets/avatar/character/body/head.svg';
import { ReactComponent as LegLeft } from '../../assets/avatar/character/body/leg-left.svg';
import { ReactComponent as LegRight } from '../../assets/avatar/character/body/leg-right.svg';
import { ReactComponent as NeckShadow } from '../../assets/avatar/character/body/neck-shadow.svg';
import { ReactComponent as Neck } from '../../assets/avatar/character/body/neck.svg';
import { ReactComponent as ShoulderLeftArm } from '../../assets/avatar/character/body/shoulder-left-arm.svg';
import { ReactComponent as ShoulderRightArm } from '../../assets/avatar/character/body/shoulder-right-arm.svg';
import { ReactComponent as Eyes } from '../../assets/avatar/character/body/eyes.svg';
import { ReactComponent as LeftFist } from '../../assets/avatar/character/body/fist-left.svg';
import { ReactComponent as EyePupils } from '../../assets/avatar/character/body/eye-pupils.svg';
import { ReactComponent as Mouth } from '../../assets/character/mouth/mouth-1.svg'
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.tsx";

import './AvatarDisplay.css';

type Size = "xs" | "s" | "m" | "l" | "xl" | "full";
type Mood = "happy" | "normal" | "bad";

interface AvatarDisplayProps {
	avatar?: Avatar,
	size?: Size,
	mood?: Mood,
	isPremium?: boolean | null
};


const AvatarDisplay: React.FC<AvatarDisplayProps> = ({
														 avatar,
														 size = "m",
														 mood = "normal",
														 isPremium = false,
													 }) => {

	const avatarContainerClassName = isPremium ? `avatar-container avatar-container--${size} avatar-container--premium` : `avatar-container avatar-container--${size}`;
	const avatarLayerClassname = `avatar-layer avatar-layer--${size}`;

	const [layers, setLayers] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		let isMounted = true;

		const checkLayerExists = (path: string) => {
			return new Promise<boolean>((resolve) => {
				const img = new Image();
				img.onload = () => resolve(true);
				img.onerror = () => resolve(false);
				img.src = path;
			});
		};

		const loadLayers = async () => {
			const newLayers: { [key: string]: boolean } = {};

			const paths = [
				avatar?.character?.current_haircut !== undefined && `${process.env.PUBLIC_URL}/assets/character/hair/hair-back-${avatar.character.current_haircut}.svg`,
				avatar?.misc?.current_chair !== undefined && `${process.env.PUBLIC_URL}/assets/misc/chair/chair-${avatar.misc.current_chair}.svg`,
				avatar?.character?.current_sneakers !== undefined && `${process.env.PUBLIC_URL}/assets/character/boots/boots-${avatar.character.current_sneakers}.svg`,
				avatar?.character?.current_pants !== undefined && `${process.env.PUBLIC_URL}/assets/character/pants/pants-${avatar.character.current_pants}.svg`,
				avatar?.character?.current_pants !== undefined && `${process.env.PUBLIC_URL}/assets/character/pants/pants-left-leg-${avatar.character.current_pants}.svg`,
				avatar?.character?.current_pants !== undefined && `${process.env.PUBLIC_URL}/assets/character/pants/pants-right-leg-${avatar.character.current_pants}.svg`,
				avatar?.character?.current_shirt !== undefined && `${process.env.PUBLIC_URL}/assets/character/shirt/shirt-${avatar.character.current_shirt}.svg`,
				avatar?.character?.current_shirt !== undefined && `${process.env.PUBLIC_URL}/assets/character/shirt/shirt-collar-${avatar.character.current_shirt}.svg`,
				avatar?.character?.current_shirt !== undefined && `${process.env.PUBLIC_URL}/assets/character/shirt/shirt-right-arm-${avatar.character.current_shirt}.svg`,
				avatar?.character?.current_shirt !== undefined && `${process.env.PUBLIC_URL}/assets/character/shirt/shirt-left-arm-${avatar.character.current_shirt}.svg`,
				avatar?.misc?.current_table !== undefined && `${process.env.PUBLIC_URL}/assets/misc/other/table/left/misc-table-left-${avatar.misc.current_table}.svg`,
				avatar?.misc?.current_table !== undefined && `${process.env.PUBLIC_URL}/assets/misc/other/table/right/misc-table-right-${avatar.misc.current_table}.svg`,
				avatar?.misc?.current_notebook !== undefined && `${process.env.PUBLIC_URL}/assets/misc/pc/pc-${avatar.misc.current_notebook}.svg`,
				avatar?.misc?.current_table !== undefined && `${process.env.PUBLIC_URL}/assets/misc/table/table-${avatar.misc.current_table}.svg`,
				avatar?.character?.current_face_type !== undefined && `${process.env.PUBLIC_URL}/assets/character/mouth/mouth-${avatar.character.current_face_type}.svg`,
				avatar?.character?.current_eyebrow_type !== undefined && `${process.env.PUBLIC_URL}/assets/character/eyebrows/eyebrows-${avatar.character.current_eyebrow_type}.svg`,
				avatar?.character?.current_haircut !== undefined && `${process.env.PUBLIC_URL}/assets/character/hair/hair-front-${avatar.character.current_haircut}.svg`
			].filter(Boolean);

			for (const path of paths) {
				const exists = await checkLayerExists(path as string);
				if (isMounted) {
					newLayers[path as string] = exists;
				}
			}

			if (isMounted) {
				setLayers(newLayers);
			}
		};

		loadLayers();

		return () => {
			isMounted = false;
		};
	}, [avatar]);

	return (
		<div className={avatarContainerClassName}>
			{layers[`${process.env.PUBLIC_URL}/assets/misc/chair/chair-${avatar?.misc?.current_chair}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/misc/chair/chair-${avatar?.misc?.current_chair}.svg`}
					 className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/hair/hair-back-${avatar?.character?.current_haircut}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/hair/hair-back-${avatar?.character?.current_haircut}.svg`}
					className={avatarLayerClassname}
					style={{filter: hexToCSSFilter(validateHexColor(avatar?.character.current_hair_color)).filter.replace(";", "")}}
				/>
			)}
			<BodyTorso className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<Feets className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<ForearmRightArm className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<LegLeft className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<LegRight className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<ShoulderLeftArm className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<ShoulderRightArm className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			{layers[`${process.env.PUBLIC_URL}/assets/character/boots/boots-${avatar?.character?.current_sneakers}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/boots/boots-${avatar?.character?.current_sneakers}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/pants/pants-${avatar?.character?.current_pants}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/character/pants/pants-${avatar?.character?.current_pants}.svg`}
					 className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/pants/pants-left-leg-${avatar?.character?.current_pants}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/pants/pants-left-leg-${avatar?.character?.current_pants}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/pants/pants-right-leg-${avatar?.character?.current_pants}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/pants/pants-right-leg-${avatar?.character?.current_pants}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-${avatar?.character?.current_shirt}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-${avatar?.character?.current_shirt}.svg`}
					 className={avatarLayerClassname} />
			)}
			<Neck className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			<NeckShadow className={avatarLayerClassname} />
			<ForearmLeftArm className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			{layers[`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-collar-${avatar?.character?.current_shirt}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-collar-${avatar?.character?.current_shirt}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-right-arm-${avatar?.character?.current_shirt}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-right-arm-${avatar?.character?.current_shirt}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-left-arm-${avatar?.character?.current_shirt}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/shirt/shirt-left-arm-${avatar?.character?.current_shirt}.svg`}
					className={avatarLayerClassname} />
			)}
			<LeftFist className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			{layers[`${process.env.PUBLIC_URL}/assets/misc/other/table/left/misc-table-left-${avatar?.misc?.current_table}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/misc/other/table/left/misc-table-left-${avatar?.misc?.current_table}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/misc/other/table/right/misc-table-right-${avatar?.misc?.current_table}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/misc/other/table/right/misc-table-right-${avatar?.misc?.current_table}.svg`}
					className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/misc/pc/pc-${avatar?.misc?.current_notebook}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/misc/pc/pc-${avatar?.misc?.current_notebook}.svg`}
					 className={avatarLayerClassname} />
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/misc/table/table-${avatar?.misc?.current_table}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/misc/table/table-${avatar?.misc?.current_table}.svg`}
					 className={avatarLayerClassname} />
			)}
			<Head className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_skin_color) }} />
			{layers[`${process.env.PUBLIC_URL}/assets/character/mouth/mouth-${avatar?.character?.current_face_type}.svg`] && (
				<img
					src={`${process.env.PUBLIC_URL}/assets/character/mouth/mouth-${avatar?.character?.current_face_type}.svg`}
					className={avatarLayerClassname} />
			)}
			<Eyes className={avatarLayerClassname} />
			<EyePupils className={avatarLayerClassname} style={{ fill: validateHexColor(avatar?.character?.current_eye_color) }} />
			<Mouth className={avatarLayerClassname} />
			{layers[`${process.env.PUBLIC_URL}/assets/character/eyebrows/eyebrows-${avatar?.character?.current_eyebrow_type}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/character/eyebrows/eyebrows-${avatar?.character?.current_eyebrow_type}.svg`}
					 className={avatarLayerClassname}
					 style={{filter: hexToCSSFilter(validateHexColor(avatar?.character.current_eyebrow_color)).filter.replace(";", "")}}
				/>
			)}
			{layers[`${process.env.PUBLIC_URL}/assets/character/hair/hair-front-${avatar?.character?.current_haircut}.svg`] && (
				<img src={`${process.env.PUBLIC_URL}/assets/character/hair/hair-front-${avatar?.character?.current_haircut}.svg`}
					 className={avatarLayerClassname}
					 style={{filter: hexToCSSFilter(validateHexColor(avatar?.character.current_hair_color)).filter.replace(";", "")}}
				/>
			)}
		</div>
	);
};

function validateHexColor(color:string | null | undefined) {
	const hexPattern = /^#([0-9A-F]{3}){1,2}$/i;

	if (!color) {
		return "#bdbdbd"
	}

	if (hexPattern.test(color)) {
		return color;
	} else {
		return "#bdbdbd";
	}
}

const WrappedAvatarDisplay: React.FC<AvatarDisplayProps> = (props) => (
	<ErrorBoundary>
		<AvatarDisplay {...props} />
	</ErrorBoundary>
);

export default WrappedAvatarDisplay;
