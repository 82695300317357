import React from 'react'
import { UserInfo } from '../../components/UserInfo/UserInfo'
import { TrendingList } from '../../components/TrendingList/TrendingList'
import { MemeTable } from '../../modules/MemeTable/MemeTable'
import PageColumnsWrapper from '../../components/PageColumnsWrapper/PageColumnsWrapper.tsx'
import {useUser} from "../../lib/hooks/useUser.ts";
import InfoPopup from "../../components/InfoPopup/InfoPopup.tsx";
import './MainPage.css'

export const MainPage = () => {

	const {token, user} = useUser();

	const infoPopupContent = "<p>Here you can see the most popular memes of the last week</p>";

	return (
		<PageColumnsWrapper
			content={
				<>
					<h2>Trending {<InfoPopup htmlContent={infoPopupContent} />}</h2>
					<TrendingList />
					<MemeTable />
				</>
			}
			rightColumn={
				<>
					<UserInfo user={user} isAuth={token} />
				</>
			}
		/>
	);
};
