import React from 'react'
import Title from "../../Title/Title.tsx";
import Doge from "../../../assets/svg/Doge.tsx";
import "./NonLoggedUser.css"

export const NonLoggedUser = () => {
	return <div className="non-logged-user-info">
		<Title size={"l"}>
			wanna trade?
		</Title>
		<Title size={"s"} appearence={"ghost"}>
			you should login to do so
		</Title>
		<Doge />
	</div>
}
