import { createElement } from "react";
import { Message, useToaster } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";


export const useNotification = () => {

    const toaster = useToaster();

    const notify = (type: TypeAttributes.Status, message: string) => {
        const MessageComponent = createElement(Message, { type: type, header: message, showIcon: true });
        toaster.push(MessageComponent);
    };

    return { notify };
};
