import {useCallback, useEffect, useState} from "react";
import { useRecoilState } from "recoil";
import { AUTH_STATE, defaultUserAvatarState, USER_STATE, UserState } from "../../core/store/user.ts";
import { getCurrentUser, getCurrentUserBalance, loginUser, registerUser } from "../../core/api/MemeTradeApi.js";
import { useNotification } from "./useNotification.ts";
import {useNavigate} from "react-router-dom";

export const useUser = () => {
    const [user, setUser] = useRecoilState(USER_STATE);
    const [auth, setAuth] = useRecoilState(AUTH_STATE);
    const { notify } = useNotification();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const initializeAuth = useCallback(async () => {
        const token = localStorage.getItem("access_token");
        if (token) {
            try {
                const userData = await getCurrentUser(token);
                const balanceData = await getCurrentUserBalance(token);
                setUser({...userData, ...balanceData});
                setAuth({
                    isAuthorized: true,
                    token: token,
                });
            } catch (error: any) {
                console.log("Error encountered while initializing auth: " + error)
                logout();
            }
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        initializeAuth();
    }, []);

    const signUp = async (login: string, password: string, email: string): Promise<boolean> => {
        try {
            return await registerUser(email, login, password);
        } catch (error: any) {
            notify("error", "Error encountered while signing in: " + error.response?.data?.detail || error.message);
            return false;
        }
    };

    const login = async (login: string, password: string): Promise<boolean> => {
        try {
            const authData = await loginUser(login, password);
            localStorage.setItem("access_token", authData.access_token);
            updateUser();
            navigate("/");
            return true;
        } catch (error: any) {
            notify("error", "Error encountered while logging in: " + error.response?.data?.detail || error.message);
            logout();
            return false;
        }
    };

    const updateUser = async () => {
        try {
            const token = localStorage.getItem("access_token");
            if (!token) return;
            const userData = await getCurrentUser(token);
            const balanceData = await getCurrentUserBalance(token);
            setUser({...userData, ...balanceData});
            setAuth({
                isAuthorized: true,
                token: token,
            });
        } catch (error: any) {
            console.log("Error encountered while initializing auth: " + error)
            logout();
        }
    };

    const logout = () => {
        setUser({
            id: null,
            avatar: defaultUserAvatarState,
            email: null,
            login: null,
            level: null,
            role: null,
            bp_active: null,
            balance: null,
            achievements: null,
            place_in_leaderboard: null,
            leaderboard_fluctuation: null,
            meme_stock: null,
            stock_fluctuation: null
        });
        setAuth({
            isAuthorized: false,
            token: null
        });
        localStorage.removeItem('access_token');
        navigate("/");
    };

    const isAuth = auth.isAuthorized;
    const token = auth.token;

    return { login, updateUser, logout, signUp, isAuth, token, user, loading };
};
