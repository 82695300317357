import { RecoilState, atom } from "recoil";

export type UserState = {
    id: number | null,
    avatar: Avatar,
    email: string | null,
    login: string | null,
    level: number | null,
    role: string | null,
    bp_active: boolean | null,
    balance: number | null,
    place_in_leaderboard: number | null,
    achievements: Achievement[] | null,
    leaderboard_fluctuation: number | null
    meme_stock: number | null,
    stock_fluctuation: number | null, // from get /user/balance
};

export type AuthState = {
    isAuthorized: boolean,
    token: string | null
}

export type Avatar = {
    character: {
        current_skin_color: string;
        current_haircut: string;
        current_hair_color: string;
        current_eyebrow_color: string;
        current_eye_color: string;
        current_eyebrow_type: string;
        current_face_type: string;
        current_shirt: string;
        current_jacket: string;
        current_pants: string;
        current_sneakers: string;
    },
    misc: {
        current_background: string;
        current_notebook: string;
        current_table: string;
        current_chair: string;
        current_misc_left: string;
        current_misc_right: string;
    }
};

export type Achievement = null; //FIXME: add achievement type

export enum ROLE {
    USER,
    ADMIN
}; // FIXME: need to change to something else

export const defaultUserAvatarState: Avatar = {
    character: {
        current_skin_color: "#D6AA8D",
        current_haircut: "1",
        current_hair_color: "#D6AA8D",
        current_eyebrow_color: "1",
        current_eye_color: "#00abba",
        current_eyebrow_type: "1",
        current_face_type: "1",
        current_shirt: "1",
        current_jacket: "1",
        current_pants: "1",
        current_sneakers: "1",
    },
    misc: {
        current_background: "1",
        current_notebook: "1",
        current_table: "1",
        current_chair: "1",
        current_misc_left: "3",
        current_misc_right: "1",
    }
};

export const defaultUserState: UserState = {
    id: null,
    avatar: defaultUserAvatarState,
    email: null,
    login: null,
    level: null,
    role: null,
    bp_active: null,
    balance: null,
    achievements: null,
    leaderboard_fluctuation: null,
    place_in_leaderboard: null,
    meme_stock: null,
    stock_fluctuation: null
};

const defaultAuthState: AuthState = {
    isAuthorized: false,
    token: null,
};

export const AUTH_STATE: RecoilState<AuthState> = atom({
    key: "AUTH_STATE",
    default: defaultAuthState,
});

export const USER_STATE: RecoilState<UserState> = atom({
    key: "USER_STATE",
    default: defaultUserState,
});

