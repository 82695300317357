import React, {useEffect, useMemo, useState} from 'react'
import LeaderboardsTable from '../../components/LeaderboardsTable/LeaderboardsTable.jsx'
import PageColumnsWrapper from "../../components/PageColumnsWrapper/PageColumnsWrapper.tsx";
import {Pagination} from 'rsuite'
import './LeaderboardsPage.css'
import {useNotification} from '../../lib/hooks/useNotification.ts';
import {getUsers} from "../../core/api/MemeTradeApi";
import {UserState} from "../../core/store/user.ts";
import UserDisplay from "../../components/UserDisplay/UserDisplay.tsx";
import InfoPopup from "../../components/InfoPopup/InfoPopup.tsx";


const LeaderboardsPage = () => {

	const [user, setUser] = useState<UserState>();
	const [leaderBoardData, setLeaderBoardData] = useState(undefined);
	const [loading, setIsLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [maximumPage, setMaximumPage] = useState(1)
	const { notify } = useNotification();
	const [sortBy, setSortBy] = useState("PLACE_IN_LEADERBOARD");
	const [order, setOrder] = useState("asc");

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			try {
				const data = await getUsers(page, order.toUpperCase(), sortBy.toUpperCase());
				setMaximumPage(data.amount_of_pages);
				setLeaderBoardData(data.users);
				setUser(data.users[0]);
			} catch (error: any) {
				notify("error", error.toString());
			} finally {
				setIsLoading(false);
			}
		}
		fetchData();
	}, [page, order, sortBy]);

	const onSortColumn = (sortBy: string, order: string) => {
			setOrder(order);
			setSortBy(sortBy);
	};

	const LeaderboardElement = useMemo(() => (
			<LeaderboardsTable
				data={leaderBoardData}
				onSortColumn={onSortColumn}
				sortColumn={sortBy}
				sortType={order}
				selectUser={setUser}
			/>
		), [leaderBoardData, order, sortBy]
	);

	const UserInfoElement = useMemo(() => (
			<>{user ? <UserDisplay user={user} /> : <></>}</>
	), [user]
	);

	const infoPopupContent = "<p>Here you can see top players in the game. You can sort them by different parameters</p>"

	return (
		<PageColumnsWrapper
			content={
				<>
					<h2>Leaderboards {<InfoPopup htmlContent={infoPopupContent}/>}</h2>
					{LeaderboardElement}
					<Pagination
						style={{ alignSelf: "center" }}
						prev
						last
						next
						first
						ellipsis
						boundaryLinks
						maxButtons={10}
						limit={10}
						total={maximumPage * 10}
						activePage={page}
						onChangePage={setPage}
					/>
				</>
			}
			rightColumn={
			<>
				{UserInfoElement}
			</>
			}
		/>
	);
};

export default LeaderboardsPage;
