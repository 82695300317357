import React, { useState, useEffect, useRef } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import "./InfoPopup.css";

interface InfoPopupProps {
    htmlContent: string;
}

const InfoPopup: React.FC<InfoPopupProps> = ({ htmlContent }) => {
    const [isVisible, setIsVisible] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);

    const togglePopup = () => {
        setIsVisible(!isVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        if (isVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isVisible]);

    return (
        <div className="info-popup" ref={popupRef}>
            <AiOutlineInfoCircle onClick={togglePopup} className="info-button"/>
            {isVisible && (
                <div className="popup-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}
        </div>
    );
};

export default InfoPopup;
