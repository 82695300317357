import React, { useState, useEffect } from 'react'
import { getUser } from '../../core/api/MemeTradeApi'
import { Form, Popover, Whisper } from 'rsuite'
import { PageLoader } from '../../components/PageLoader/PageLoader'
import InfoOutlineIcon from '@rsuite/icons/InfoOutline'
import { UserInfo } from '../../components/UserInfo/UserInfo'
import PageColumnsWrapper from "../../components/PageColumnsWrapper/PageColumnsWrapper.tsx";

import './SettingsPage.css'

export const SettingsPage = () => {
	// initial value для стейтов установить из запроса, как к бэку подключусь
	const [user, setUser] = useState()
	const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false)
	const [formData, setFormData] = useState()

	const LoginPopover = (
		<Popover title='Attention'>
			You can change it only once per month
		</Popover>
	)

	const EmailPopover = (
		<Popover title='Attention'>
			You can change it only once per six month
		</Popover>
	)

	useEffect(() => {
		const fetchData = async () => {
			const userData = await getUser(1)
			setUser(userData)
		}
		fetchData()
	}, [])

	const onFinishHandler = () => {}

	return (
		<PageColumnsWrapper content={
			<div className='settingsPageContainer'>
				<div className='settingsPageContent'>
					<div className='settingsPageTitle'>Settings</div>
					{user ? (
						<Form
							className='settingsPageForm'
							onChange={(value) => {
								setFormData(value)
								console.log(value)
							}}
						>
							<Form.Group controlId='login'>
								<Form.ControlLabel>Login</Form.ControlLabel>
								<Form.Control
									name='login'
									placeholder={user?.login}
								/>
								<Whisper
									placement='auto'
									trigger='click'
									speaker={LoginPopover}
								>
									<InfoOutlineIcon className='settingsPagePopover' />
								</Whisper>
							</Form.Group>
							<Form.Group controlId='email'>
								<Form.ControlLabel>Email</Form.ControlLabel>
								<Form.Control
									name='email'
									placeholder={user?.email}
								/>
								<Whisper
									placement='auto'
									trigger='click'
									speaker={EmailPopover}
								>
									<InfoOutlineIcon className='settingsPagePopover' />
								</Whisper>
							</Form.Group>
							<Form.Group controlId='oldPassword'>
								<Form.ControlLabel>Old Password</Form.ControlLabel>
								<Form.Control
									name='oldPassword'
									type='password'
									autoComplete='off'
								/>
							</Form.Group>

							{isOldPasswordCorrect && (
								<>
									<Form.Group controlId='newPassword'>
										<Form.ControlLabel>
											New Password
										</Form.ControlLabel>
										<Form.Control
											name='newPassword'
											type='password'
											autoComplete='off'
										/>
									</Form.Group>
									<Form.Group controlId='newPasswordConfirm'>
										<Form.ControlLabel>
											New Password Confirm
										</Form.ControlLabel>
										<Form.Control
											name='newPasswordConfirm'
											type='password'
											autoComplete='off'
										/>
									</Form.Group>
								</>
							)}
						</Form>
					) : (
						<PageLoader />
					)}
				</div>
			</div>
		} rightColumn={<UserInfo isAuth={true}/>}/>
	)
}
