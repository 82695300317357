import "./NotAuthorizedPage.css"
import React from "react";

const NotAuthorizedPage = () => {


    return (
        <div className="not-authorized-page">
            <h1 className="not-authorized-page__title">You're not authorized</h1>
            <h3 className="not-authorized-page__subtitle">Have you really thought that would work?</h3>
            <img className="not-authorized-page__image" src="/assets/unhappy-doge.png" alt="unhappy doge"/>
            <h4>You should login, click it</h4>
        </div>
    );
};

export default NotAuthorizedPage;
