import React, { HTMLAttributes } from "react";
import { Button, Container, Divider, Stack } from "rsuite";
import KeyValuePairDisplay from "../KeyValuePairDisplay/KeyValuePairDisplay.tsx";
import Title from "../Title/Title.tsx";
import "./MemeInfo.css"

interface MemeInfoProps extends HTMLAttributes<HTMLDivElement> {
    price: number,
    freeFloat: number,
    marketCap: number,
    picture: string,
    canMakeTransaction: boolean
    openModal: (isSelling: boolean) => void
};

const MemeInfo: React.FC<MemeInfoProps> = ({
    price,
    freeFloat,
    marketCap,
    picture,
    canMakeTransaction,
    openModal
}) => {

    return (
        <div className="meme-info">
            {picture ? <img className="meme-info__picture" alt="meme" src={picture} /> : <div style={{ width: 250, height: 250, backgroundColor: "black" }}></div>}
            <h4 className="meme-info__title">Assets</h4>
            <KeyValuePairDisplay label="Price" value={price.toFixed(2)} />
            <KeyValuePairDisplay label="Freefloat" value={freeFloat} />
            <KeyValuePairDisplay label="Market Cap" value={marketCap} />
            {
                canMakeTransaction &&
                <>
                    <Divider></Divider>
                    <KeyValuePairDisplay label="Your shares" value={0} />
                    <KeyValuePairDisplay label="Value" value={0} />
                    <Divider></Divider>
                    <div className="meme-info__buttons">
                        <Button style={{width: "47%"}} onClick={() => openModal(false)} appearance="primary">Buy</Button>
                        <Button style={{width: "47%"}} onClick={() => openModal(true)}>Sell</Button>
                    </div>
                </>
            }
        </div>
    );
};

export default MemeInfo;