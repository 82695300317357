import ApiService from './ApiService'
import qs from "qs";


export const getMemeList = async (page, page_size, title, sort_by, order) => {
    return ApiService.get('/meme', { params: { page, title, page_size, sort_by, order } }).then((response) => response.data)
}

export const getMeme = async (memeId) => {
    return ApiService.get(`/meme/${memeId}`).then((response) => response.data)
}

export const getMemeGraph = async (memeId) => {
    return ApiService.get(`/meme/${memeId}/graph?timeframe=ALL_TIME&interval=DAY`).then((response) => response.data)
}

export const makeTransaction = async (memeId, transaction, token) => {
    return ApiService.post(`/meme/${memeId}/transaction`, transaction, { headers: { Authorization: `bearer ${token}` } })
}

export const getUser = async (userId) => {
    return ApiService.get(`user/${userId}`).then((response) => response.data)
}

export const registerUser = async (email, login, password) => {
    return ApiService.post(`user`, { login, email, password }).then((response) => response.data)
}

export const loginUser = async (username, password) => {
    return ApiService.post(`login`, qs.stringify({ username, password })).then((response) => response.data)
}

export const getCurrentUser = async (token) => {
    return ApiService.get(`profile`, { headers: { Authorization: `bearer ${token}` } }).then((response) => response.data)
}

export const addMemeToFavorite = async (token, memeId) => {

    const response = ApiService.post(`/meme/${memeId}/favorite`, {}, { headers: { Authorization: `bearer ${token}` } }).then((response) => response.data);

    const processedData = response;

    return processedData;
}

export const removeMemeFromFavorite = async (token, memeId) => {
    const response = ApiService.delete(`/meme/${memeId}/favorite`, { headers: { Authorization: `bearer ${token}` } }).then((response) => response.data);

    const processedData = response;

    return processedData;
}

export const getTrendingMemes = async (numberOfMemes) => {
    return ApiService.get('/meme', { params: { page_size: numberOfMemes } }).then((response) => response.data)
}

export const getUserMemes = async (token, isFavorite) => {
    return ApiService.get(`/user/meme?favorite=${isFavorite}`, {headers: {Authorization: `bearer ${token}`}}).then((response) => response.data)
}

export const getUsers = async (page, order, sort_by) => {
    return ApiService.get('/user', { params: { page, order, sort_by } }).then((response) => response.data)
}

export const getCurrentUserBalance = async (token) => {
    return ApiService.get(`/user/balance`, { headers: { Authorization: `bearer ${token}` }}).then((response) => response.data);
}

export const getUserTransactions = async (token, page, page_size) => {
    return ApiService.get(`/user/transaction`, {headers: {Authorization: `bearer ${token}`}, params: {page, page_size}}).then((response) => response.data);
};

export const patchUserAvatar = async (token, avatar) => {
    return ApiService.patch("/user/avatar", avatar, {headers: {Authorization: `bearer ${token}`}}).then(response => response.data)
}

export const getUserGraph = async (token) => {
     return ApiService.get("/user/graph", {headers: {Authorization: `bearer ${token}`}}).then(response => response.data)
}
