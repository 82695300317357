import React, { useState } from 'react'
import { ShopPack } from '../../components/ShopPack/ShopPack'
import dogeIcon from '../../assets/img/icons/dogeIcon.png'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { Button } from 'rsuite'
import './ShopPage.css'
import AvatarSelector from "../../components/AvatarSelector/AvatarSelector.tsx";
import {useUser} from "../../lib/hooks/useUser.ts";
import {useNotification} from "../../lib/hooks/useNotification.ts";
import {patchUserAvatar} from "../../core/api/MemeTradeApi";

export const ShopPage = () => {

	const [shopPackItems, setShopPackItems] = useState([
		{
			id: 1,
			text: '+5000$',
			img: dogeIcon,
		},
		{
			id: 2,
			text: '+5000$',
			img: dogeIcon,
		},
		{
			id: 3,
			text: '+5 Random Memes',
			img: dogeIcon,
		},
		{
			id: 4,
			text: '2x discount on transactions',
		},
	])

	const [shopItems, setShopItems] = useState([
		{
			id: 1,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
		{
			id: 2,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
		{
			id: 3,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
		{
			id: 4,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
		{
			id: 5,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
		{
			id: 6,
			title: 'shut up and give me money!',
			price: '101,820',
			productText: '+5000$',
			productImg: dogeIcon,
		},
	])

	const [traderPassItems, setTraderPassItems] = useState([
		{
			id: 1,
			text: '+5000$',
		},
		{
			id: 2,
			text: '+5000$',
		},
	])

	const mapShopItems = () => {
		return shopItems.map((shopItem) => {
			return (
				<div className='shopItemContainer'>
					<div className='shopItemTitle'>{shopItem.title}</div>
					<div className='shopItemProduct'>
						<div>{shopItem.productText}</div>
						<img
							src={shopItem.productImg}
							alt={shopItem.productImg}
						/>
					</div>
					<Button className='shopItemButton'>
						<AiOutlineShoppingCart /> {shopItem.price}
					</Button>
				</div>
			)
		})
	}

	const tags = ["Shop", "Avatar"];
	const [selectedTag, setSelectedTag] = useState(0)

	const {token, user} = useUser();
	const {notify} = useNotification();

	const mapTags = () => {
		return tags.map((tag, index) => (
			<Button
				appearance={selectedTag === index ? "primary" : "ghost"}
				onClick={() => setSelectedTag(index)}>
				{tag}
			</Button>
		))
	};

	const onAvatarSelect = (avatar) => {
		patchUserAvatar(token, avatar).catch(e => notify("error", e.message)).then(() => {
			notify("success", "Avatar updated successfully!")
		})
	};


	return (
		<div className="shop-page">
			<h2>Shop</h2>
			<div className='shop-page__tags-row'>
				{mapTags()}
			</div>
			{
				selectedTag === 0 ? <div className="shop-page__content">
				<ShopPack
					title='Dank Trader Starter Pack'
					subTitle='limited time offer!'
					items={shopPackItems}
					price='5000$'
					color='red'
				/>
				<div className='shopItems'>{mapShopItems()}</div>
				<ShopPack
					title='Trader Pass'
					items={traderPassItems}
					price='5000$'
					color='purple'
				/>
			</div> : <div className="shop-page__content">
					<AvatarSelector  onAvatarSelect={onAvatarSelect} initialState={user.avatar}/>
				</div>
			}
		</div>

	)
}
