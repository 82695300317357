import React from "react";
import './PageColumnsWrapper.css'


interface PageColumnsWrapperProps {
    content: React.ReactElement;
    rightColumn: React.ReactElement;
};

const PageColumnsWrapper: React.FC<PageColumnsWrapperProps> = ({ content, rightColumn }) => {
    return (
        <div className="page-columns-wrapper">
            <div className="page-columns-wrapper__content">
                {content}
            </div>
            <div className="page-columns-wrapper__right-column">
                {rightColumn}
            </div>
        </div>
    );
};

export default PageColumnsWrapper;
